import React, { memo, PropsWithChildren, ReactNode } from 'react';
import { Segment, Header, SegmentProps } from 'semantic-ui-react';

export interface SectionProps {
  header?: ReactNode;
}
export const Section = memo<PropsWithChildren<SectionProps & SegmentProps>>(
  ({ header, children, ...props }) => {
    return (
      <>
        <Segment secondary {...props}>
          {header && <Header dividing content={header} />}
          {children}
        </Segment>
      </>
    );
  },
);
