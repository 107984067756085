import React from 'react';
import { Dropdown, Input, List, Menu } from 'semantic-ui-react';
import { useListFilter } from 'shared/hooks';

interface TrashFilter {
  term?: string;
  startDate?: string;
  endDate?: string;
  store?: number;
}

interface Props {
  stores: API.Store[];
}
export function useTrashFilter(props: Props) {
  const filter = useListFilter<TrashFilter, API.InventoryArticle>(
    ({ onChange, values }) => (
      <>
        <Menu.Item>
          <Dropdown
            value={values.store}
            clearable
            placeholder="Filiale"
            selection
            onChange={(e: any, { value }) => {
              onChange('store', value);
            }}
            options={props.stores.map((s) => ({ text: s.name, value: s.id }))}
          />
        </Menu.Item>

        <Menu.Item>
          <Input
            placeholder="Suche"
            onChange={(e) => onChange('term', e.target.value)}
            value={values.term ? values.term : ''}
          />
        </Menu.Item>
        <List.Item>
          <Input
            type="date"
            placeholder="Anfangsdatum"
            onChange={(e) => onChange('startDate', e.target.value)}
            value={values.startDate ? values.startDate : ''}
          />
        </List.Item>
        <List.Item>
          <Input
            type="date"
            placeholder="Enddatum"
            onChange={(e) => onChange('endDate', e.target.value)}
            value={values.endDate ? values.endDate : ''}
          />
        </List.Item>
      </>
    ),
    {
      endpointUrl: 'admin/trash',
      initialState: {
        startDate: undefined,
        term: undefined,
        endDate: undefined,
        store: undefined,
      },
    }
  );

  return filter;
}
