import React, { ComponentType } from 'react';
import { Icon } from 'semantic-ui-react';
interface Props {
  value: boolean;
}
export const YesNoLabel: ComponentType<Props> = props => {
  const { value } = props;
  return (
    <Icon
      color={value ? 'green' : 'grey'}
      name={value ? 'thumbs up' : 'thumbs down'}
    />
  );
};
