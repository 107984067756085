import { onApplicationMenu, onTopLevelRoutes } from 'core';
import Router from './components/Router';

onTopLevelRoutes(() => ({
  path: '/article',
  secure: true,
  component: Router,
}));

onApplicationMenu(() => ({
  group: 'stock',
  label: 'articles',
  icon: 'boxes',
  to: '/article',
}));
