import articleDummy from 'assets/images/article.png';
import {
  axios,
  createEndpoint,
  useRemoteList,
  useRemoteSingle,
  useSessionUser,
} from 'core';
import { Field, FieldProps } from 'formik';
import React, { ComponentType, useCallback, useEffect } from 'react';
import CurrencyInput from 'react-currency-input';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Form, Grid, Image } from 'semantic-ui-react';
import { Editor, Section } from 'shared/components';
import { MarkdownEditor } from 'shared/components/markdown-editor/markdown-editor.component';
import { Uploader } from 'shared/components/uploader/uploader.component';
import { array, boolean, object, string } from 'yup';
import { SubMenu } from './SubMenu';

interface Props {}

export interface Values {
  name: string;
  isAvailableOnline: boolean;
  description: string;
  slug: string;
  tags: API.Tag[];
}

export const schema = object().shape({
  name: string().required().min(3),
  isAvailableOnline: boolean(),
  slug: string().min(3).required(),
  description: string(),
  tagIds: array(),
});

export const defaults: any = {
  name: '',
  isAvailableOnline: false,
  description: '',
  slug: '',
  tagIds: [],
};

export const ArticleEditor: ComponentType<Props> = (props) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const user = useSessionUser();
  const { addToast } = useToasts();
  const tags = useRemoteList(createEndpoint<API.Tag>('tags'));
  const article = useRemoteSingle(
    createEndpoint<API.InventoryArticle>('admin/article')
  );

  const backToList = useCallback(() => {
    history.push('/article');
  }, []);

  const onSave = useCallback(() => {
    addToast('Der Artikel wurde gespeichert.', {
      appearance: 'success',
      autoDismiss: true,
    });
    //backToList();
  }, []);

  useEffect(() => {
    article.load(parseInt(id)).catch(backToList);
  }, [id]);

  return (
    <Editor
      submenu={<SubMenu />}
      title={article.data?.id ? `Artikel #${article.data.id}` : 'Neuer Artikel'}
      endpoint="admin/article"
      initialValues={defaults}
      validationSchema={schema}
      onCancel={backToList}
      onSave={onSave}
      id={parseInt(id)}
    >
      {(formik) => (
        <Grid columns={2}>
          <Grid.Column>
            <Section
              header={
                article.data?.id
                  ? `Artikel #${article.data.id} (Besitzer: ${article.data.ownerStore?.name}, Standort: ${article.data.currentStore?.name})`
                  : 'Neuer Artikel'
              }
            >
              <Field
                name="name"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['name']}
                    label="Name"
                    {...field}
                  />
                )}
              />
              <Field
                name="slug"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['slug']}
                    label="Slug"
                    {...field}
                  />
                )}
              />
              <Field
                name="description"
                render={({ field }: FieldProps) => (
                  <MarkdownEditor
                    error={formik.errors['description']}
                    label="Beschreibung"
                    {...field}
                    onChange={(value) =>
                      formik.setFieldValue('description', value)
                    }
                  />
                )}
              />

              <div className="field">
                <Field
                  name="price"
                  render={({ field, form }: FieldProps) => (
                    <>
                      <label>Preis</label>
                      <Form.Input
                        as={CurrencyInput}
                        error={formik.errors['price']}
                        label="Preis"
                        suffix=" €"
                        decimalSeparator=","
                        thousandSeparator="."
                        {...field}
                        value={field.value * 0.01}
                        onChange={(e, value) =>
                          form.setFieldValue(
                            'price',
                            ((value as unknown as number) * 100).toString()
                          )
                        }
                      />
                    </>
                  )}
                />
              </div>
              <div className="field">
                <label>Verfügbarkeit</label>
                <Field
                  name="sellable"
                  render={({ field }: FieldProps) => {
                    return (
                      <Form.Checkbox
                        error={formik.errors['sellable']}
                        label="Artikel ist käuflich"
                        onChange={(_, element) => {
                          formik.setFieldValue('sellable', element.checked);
                        }}
                        checked={field.value}
                      />
                    );
                  }}
                />
              </div>

              <Field
                name="size"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['size']}
                    label="Größe"
                    {...field}
                  />
                )}
              />

              <Field
                name="color"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['color']}
                    label="Farbe"
                    {...field}
                  />
                )}
              />

              <Field
                name="material"
                render={({ field }: FieldProps) => (
                  <MarkdownEditor
                    error={formik.errors['material']}
                    label="Material"
                    {...field}
                    onChange={(value) =>
                      formik.setFieldValue('material', value)
                    }
                  />
                )}
              />

              <Field
                name="notes"
                render={({ field }: FieldProps) => (
                  <Form.TextArea
                    error={formik.errors['notes']}
                    label="Notizen"
                    {...field}
                  />
                )}
              />

              <Field
                name="cooperation"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['cooperation']}
                    label="Kooperation"
                    {...field}
                  />
                )}
              />

              <div className="field">
                <label>Spende</label>
                <Field
                  name="donation"
                  render={({ field }: FieldProps) => {
                    return (
                      <Form.Checkbox
                        error={formik.errors['donation']}
                        label="Artikel ist eine Spende"
                        onChange={(_, element) => {
                          formik.setFieldValue('donation', element.checked);
                        }}
                        checked={field.value}
                      />
                    );
                  }}
                />
              </div>

              <Field
                name="designer"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['designer']}
                    label="Designer"
                    {...field}
                  />
                )}
              />

              <Field
                name="maxLendingTime"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    type="number"
                    error={formik.errors['maxLendingTime']}
                    label="Max. Leihdauer"
                    {...field}
                  />
                )}
              />

              <div className="field">
                <label>Sichtbarkeit</label>
                <Field
                  name="isAvailableOnline"
                  render={({ field }: FieldProps) => {
                    return (
                      <Form.Checkbox
                        error={formik.errors['isAvailableOnline']}
                        label="Im Webshop anzeigen"
                        onChange={(_, element) => {
                          formik.setFieldValue(
                            'isAvailableOnline',
                            element.checked
                          );
                        }}
                        checked={field.value}
                      />
                    );
                  }}
                />
              </div>
            </Section>
          </Grid.Column>
          <Grid.Column>
            <Section header="Tags">
              <Form.Select
                fluid
                multiple
                search
                onChange={(event, data) => {
                  if (data.value && Array.isArray(data.value) && tags.data) {
                    formik.setFieldValue('tagIds', data.value);
                  }
                }}
                clearable
                value={formik.values['tagIds'] || []}
                options={
                  tags.data?.map((tag) => ({
                    key: tag.id,
                    text: tag.name,
                    value: tag.id,
                  })) || []
                }
              />
              {formik.errors['tagIds'] && <div>{formik.errors['tagIds']}</div>}
            </Section>
            <Section header="Bilder">
              {article.data?.id &&
                article.data?.ownerStore?.id === user.store.id && (
                  <Uploader
                    onChange={async (files: File[]) => {
                      const data = new FormData();
                      data.append('file', files[0]);

                      const response = await axios.patch(
                        `${process.env.BACKEND_URL}/stores/${article.data?.ownerStore?.id}/articles/${article.data?.id}/image`,
                        data,
                        {
                          headers: {
                            'Content-Type':
                              'multipart/formdata;boundary=MultipartBoundary',
                          },
                        }
                      );
                      if (article.data?.id) {
                        article.load(article.data.id);
                      }
                    }}
                    trigger={
                      <Image
                        src={
                          article.data?.imageUrl
                            ? `${process.env.BACKEND_URL}${article.data?.imageUrl}`
                            : articleDummy
                        }
                      />
                    }
                  />
                )}
              {article.data?.id &&
                article.data?.ownerStore?.id !== user.store.id && (
                  <>
                    <p>
                      Der Artikel ist Eigentum der{' '}
                      {article.data.ownerStore?.name} und kann von dir nicht
                      bearbeitet werden :-(
                    </p>
                    <Image
                      src={
                        article.data?.imageUrl
                          ? `${process.env.BACKEND_URL}${article.data?.imageUrl}`
                          : articleDummy
                      }
                    />
                  </>
                )}
            </Section>
          </Grid.Column>
        </Grid>
      )}
    </Editor>
  );
};
