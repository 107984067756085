import { onTopLevelRoutes, onApplicationMenu } from 'core';
import { lazy } from 'react';

onTopLevelRoutes(() => ({
  path: '/auth',
  secure: false,
  pageFallback: false,
  component: lazy(() => import('./components/Router')),
}));

onApplicationMenu(() => ({
  group: 'auth',
  label: 'logout',
  icon: 'power off',
  to: '/auth/logout',
}));
