import { useTranslator } from 'core';
import React, { ComponentType, lazy } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ArticleEditor } from './ArticleEditor';
import ArticlePositions from './ArticlePositions';

const ArticleDataTable = lazy(() => import('./ArticleDataTable'));

const Router: ComponentType = () => {
  const history = useHistory();
  const _ = useTranslator();
  return (
    <Switch>
      <Route exact path={'/article'}>
        <Redirect to="/article/list" />
      </Route>

      <Route path="/article/list">
        <ArticleDataTable />
      </Route>

      <Route path="/article/position">
        <ArticlePositions />
      </Route>

      <Route path="/article/:id/edit">
        <ArticleEditor />
      </Route>
    </Switch>
  );
};

export default Router;
