import { createEndpoint, useEndpoint, useTranslator } from 'core';
import { format, startOfMonth } from 'date-fns';
import React, {
  ComponentType,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Icon, Menu, Table } from 'semantic-ui-react';
import {
  Currency,
  DataTable,
  FormattedDate,
  Page,
  PaymentType,
} from 'shared/components';
import { useArticleDisposalFilter } from './ArticleDisposalFilter';

export type ArticleDisposalEntry = API.ArticleDisposal & {
  disposal: API.Disposal & { store: API.Store };
};

const ArticleDisposalDataTable: ComponentType = () => {
  const _ = useTranslator();
  const [storeEndpoint] = useEndpoint<API.Store>(createEndpoint('stores'));
  const [stores, setStores] = useState<API.Store[]>([]);
  const exportEndpoint = createEndpoint<API.DisposalReportExport>(
    `admin/disposal/article-disposal/export`
  );

  const [filter, { list, query }] = useArticleDisposalFilter(
    {
      orderBy: 'disposal.createdAt',
      order: 'DESC',
      startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      endDate: undefined,
      articleOwner: undefined,
      disposalStore: undefined,
    },
    stores
  );

  const onExport = useCallback(async () => {
    const result = await exportEndpoint.custom({
      method: 'get',
      responseType: 'blob',
      params: query,
    });
    const file = new Blob([result], { type: 'text/csv' });
    window.open(URL.createObjectURL(file));
  }, [query]);

  useEffect(() => {
    storeEndpoint.get().then((response) => setStores(response));
  }, []);

  return (
    <Page
      header={_('menu.disposals')}
      loading={list.loading}
      actions={
        <>
          <Menu.Item onClick={onExport}>
            <Icon name="file" />
            CSV Export
          </Menu.Item>
        </>
      }
    >
      {filter}
      <DataTable<ArticleDisposalEntry>
        query={query}
        list={list}
        rowComponent={({ data, ...props }) => (
          <Table.Row {...props}>
            <Table.Cell>
              <FormattedDate value={data.disposal.createdAt} />
            </Table.Cell>
            <Table.Cell>{data.disposal.id}</Table.Cell>
            <Table.Cell>
              <Icon
                name={
                  data.disposal.pointOfSale === 'online'
                    ? 'internet explorer'
                    : 'warehouse'
                }
              />
            </Table.Cell>
            <Table.Cell>{data.disposal.store.name}</Table.Cell>
            <Table.Cell>{data.article.ownerStore?.name}</Table.Cell>
            <Table.Cell>{data.article.name}</Table.Cell>
            <Table.Cell>
              <Currency
                value={data.amount}
                originalValue={data.article.price}
              />
            </Table.Cell>
            <Table.Cell>
              <PaymentType type={data.disposal.paymentType} />
            </Table.Cell>
            <Table.Cell>
              {data.disposal.customer
                ? `${data.disposal.customer.firstName} ${data.disposal.customer.lastName}`
                : ''}
            </Table.Cell>
          </Table.Row>
        )}
      >
        {(sorting: any) => (
          <Table.Row>
            <Table.HeaderCell
              sorted={sorting.isSorted('disposal.createdAt')}
              onClick={() => sorting.onSortChange('disposal.createdAt')}
              content="Datum"
            />
            <Table.HeaderCell
              sorted={sorting.isSorted('disposal.id')}
              onClick={() => sorting.onSortChange('disposal.id')}
              content="Verkauf"
            />
            <Table.HeaderCell
              sorted={sorting.isSorted('disposal.pointOfSale')}
              onClick={() => sorting.onSortChange('disposal.pointOfSale')}
              content="Point Of Sale"
            />
            <Table.HeaderCell
              sorted={sorting.isSorted('store.name')}
              onClick={() => sorting.onSortChange('store.name')}
              content="Verkauftsort"
            />
            <Table.HeaderCell
              sorted={sorting.isSorted('ownerStore.name')}
              onClick={() => sorting.onSortChange('ownerStore.name')}
              content="Bestand von"
            />
            <Table.HeaderCell
              sorted={sorting.isSorted('article.name')}
              onClick={() => sorting.onSortChange('article.name')}
              content="Artikel"
            />
            <Table.HeaderCell
              content="Betrag"
              sorted={sorting.isSorted('articleDisposal.amount')}
              onClick={() => {
                sorting.onSortChange('articleDisposal.amount');
              }}
            />
            <Table.HeaderCell
              content="Zahlungsart"
              sorted={sorting.isSorted('disposal.paymentType')}
              onClick={() => {
                sorting.onSortChange('disposal.paymentType');
              }}
            />
            <Table.HeaderCell
              content="Kundin"
              sorted={sorting.isSorted('customer.lastName')}
              onClick={() => sorting.onSortChange('customer.lastName')}
            />
          </Table.Row>
        )}
      </DataTable>
    </Page>
  );
};

export default memo(ArticleDisposalDataTable);
