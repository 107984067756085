import { ITEMS_PER_PAGE, useTranslator } from 'core';
import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import CustomerDataTable from './CustomerDataTable';
import { CustomerEditor } from './CustomerEditor';

export const Router = () => {
  const _ = useTranslator();

  const history = useHistory();

  return (
    <Switch>
      <Route path="/customer/list">
        <CustomerDataTable query={{ take: ITEMS_PER_PAGE }} />
      </Route>
      <Route path="/customer/:id/edit">
        <CustomerEditor />
      </Route>
      <Route exact path="/customer">
        <Redirect to="/customer/list" />
      </Route>
    </Switch>
  );
};
