import {
  ApplicationMenuItem,
  getApplicationMenu,
  useSessionUser,
  useTranslator,
} from 'core';
import { groupBy, prop } from 'ramda';
import React, { Fragment, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';

export const AppMenu = memo(() => {
  const location = useLocation();
  const user = useSessionUser();
  const grouped = groupBy(prop('group'), getApplicationMenu());
  const _ = useTranslator();

  const filter = (item: ApplicationMenuItem) => {
    if (user === undefined) {
      return false;
    }
    return true;
  };

  const groupOrder: string[] = ['common', 'business', 'stock', 'auth'];

  const markup = Object.entries(grouped)
    .map(
      ([group, items]) =>
        [group, items.filter(filter)] as [string, ApplicationMenuItem[]]
    )
    .filter(([, items]) => items.length > 0)
    .sort((g1, g2) => groupOrder.indexOf(g1[0]) - groupOrder.indexOf(g2[0]))
    .map(([group, items]) => {
      return (
        <Fragment key={group}>
          <Menu.Item>
            <Menu.Header content={_(`menu.${group}`)} />
            <Menu.Menu>
              {items.map((item) => (
                <Menu.Item
                  active={location.pathname.startsWith(item.to || '#')}
                  key={item.label}
                  as={item.to ? Link : Menu.Item}
                  to={item.to}
                >
                  <Icon name={item.icon} />
                  {_(`menu.${item.label}`)}
                </Menu.Item>
              ))}
            </Menu.Menu>
          </Menu.Item>
        </Fragment>
      );
    });

  return <>{markup}</>;
});
