import React, { ComponentType } from 'react';
import packageJson from '../../../package.json';

export const AppFooter: ComponentType = () => {
  return (
    <div>
      <p style={{ textAlign: 'right', margin: '8px' }}>
        v{packageJson.version}
      </p>
    </div>
  );
};
