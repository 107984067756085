import { onApplicationMenu, onTopLevelRoutes } from 'core';
import Router from './components/Router';

onTopLevelRoutes(() => ({
  path: '/settings',
  secure: true,
  component: Router,
}));

onApplicationMenu(() => ({
  group: 'common',
  label: 'settings',
  icon: 'wrench',
  to: '/settings',
}));
