import React from 'react';
import { Dropdown, Input, List, Menu } from 'semantic-ui-react';
import { useListFilter } from 'shared/hooks';

interface DisposalFilter {
  term?: string;
  startDate?: string;
  endDate?: string;
  store?: number;
  pointOfSale?: API.PointOfSale;
  unpayed?: boolean;
}

export function useDisposalFilter(initialQuery: any = {}, stores: API.Store[]) {
  const filter = useListFilter<DisposalFilter, API.Disposal>(
    ({ onChange, values }) => (
      <>
        <Menu.Item>
          <Dropdown
            value={values.store}
            clearable
            placeholder="Verkaufsort"
            selection
            onChange={(e: any, { value }) => {
              onChange('store', value);
            }}
            options={stores.map((s) => ({ text: s.name, value: s.id }))}
          />
        </Menu.Item>

        <Menu.Item>
          <Dropdown
            size="mini"
            value={values.pointOfSale}
            clearable
            placeholder="Point Of Sale"
            selection
            onChange={(e: any, { value }) => {
              onChange('pointOfSale', value);
            }}
            options={[
              { text: 'Online', value: 'online' },
              { text: 'Store', value: 'store' },
            ]}
          />
        </Menu.Item>

        <Menu.Item>
          <Input
            size="mini"
            placeholder="Kundin"
            onChange={(e) => onChange('term', e.target.value)}
            value={values.term ? values.term : ''}
          />
        </Menu.Item>
        <List.Item>
          <Input
            size="mini"
            type="date"
            placeholder="Anfangsdatum"
            onChange={(e) => onChange('startDate', e.target.value)}
            value={values.startDate ? values.startDate : ''}
          />
          <br />
          <Input
            type="date"
            size="mini"
            placeholder="Enddatum"
            onChange={(e) => onChange('endDate', e.target.value)}
            value={values.endDate ? values.endDate : ''}
          />
        </List.Item>

        <Menu.Item>
          <Dropdown
            size="mini"
            value={values.unpayed}
            clearable
            placeholder="Bezahlt"
            selection
            onChange={(e: any, { value }) => {
              onChange('unpayed', value);
            }}
            options={[
              { text: 'Bezahlt', value: false },
              { text: 'Unbezahlt', value: true },
            ]}
          />
        </Menu.Item>
      </>
    ),
    {
      endpointUrl: 'admin/disposal/order',
      initialState: initialQuery,
    }
  );

  return filter;
}
