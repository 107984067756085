import React, { ComponentType } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ActionMenu } from 'shared/components';
import ArticleDisposalDataTable from './ArticleDisposalDataTable';
import DisposalDataTable from './DisposalDataTable';
import { DisposalDetail } from './DisposalDetail';

const Router: ComponentType = () => {
  return (
    <Switch>
      <Route path="/disposal/article-disposal">
        <ArticleDisposalDataTable />
      </Route>
      <Route exact path="/disposal/order">
        <DisposalDataTable />
      </Route>
      <Route path="/disposal/order/:id">
        <DisposalDetail />
      </Route>
      <Route exact path="/disposal">
        <Redirect to="/disposal/order" />
      </Route>
    </Switch>
  );
};

export default Router;
ActionMenu;
