import { createEndpoint, Translator, useRemoteSingle } from 'core';
import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Button, Form, Grid, Icon, Table } from 'semantic-ui-react';
import {
  Currency,
  FormattedDate,
  Page,
  PaymentType,
  Section,
} from 'shared/components';
import { LabeledAddress } from 'shared/components/labeled-address/labeled-address.component';
import { LabeledItem } from 'shared/components/labeled-item/labeled-item.component';
import { PaymentStatus } from 'shared/components/PaymentStatus';
import { PayPalTransactionStatus } from 'shared/components/PayPalTransactionStatus';
import { ShippingStatus } from 'shared/components/ShippingStatus';
interface Props {}
export const DisposalDetail: ComponentType<Props> = (props) => {
  const _ = Translator.useTranslator();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { addToast } = useToasts();
  const [shippingCode, setShippingCode] = useState('');
  const shippingEndpoint = createEndpoint('admin/disposal/order');

  const backToList = useCallback(() => {
    history.push('/disposal/order');
  }, []);

  const disposal = useRemoteSingle(
    createEndpoint<API.Disposal>('admin/disposal/order')
  );

  const markOrderAsShipped = useCallback(async () => {
    if (disposal.data) {
      await shippingEndpoint.custom({
        url: `${disposal.data.id}/shipped`,
        method: 'PUT',
        data: {
          shippingCode,
        },
      });
      disposal.load(disposal.data.id);
    }
  }, [disposal.data, shippingCode]);

  const markOrderAsPayed = useCallback(async () => {
    if (disposal.data) {
      await shippingEndpoint.patch(disposal.data.id, 'payed');
      disposal.load(disposal.data.id);
    }
  }, [disposal.data]);

  const markOrderAsCollected = useCallback(async () => {
    if (disposal.data) {
      await shippingEndpoint.custom({
        url: `${disposal.data.id}/collected`,
        method: 'PATCH',
      });
      disposal.load(disposal.data.id);
    }
  }, [disposal.data, shippingCode]);

  useEffect(() => {
    disposal.load(parseInt(id)).catch(backToList);
  }, [id]);

  return (
    <Page
      header={`Bestellung #${disposal.data?.id}`}
      loading={disposal.loading}
    >
      {disposal.data && (
        <>
          <Grid columns={2}>
            <Grid.Row stretched>
              <Grid.Column>
                <Section header={`Bestellung ${disposal.data.id}`}>
                  <LabeledItem
                    label="Erstellt am"
                    value={<FormattedDate value={disposal.data.createdAt} />}
                  />
                  <LabeledItem
                    label="Verkaufsort"
                    value={disposal.data.store.name}
                  />
                  <LabeledItem
                    label="Kundin"
                    value={
                      disposal.data.customer ? (
                        <>
                          <Link to={`/customer/${disposal.data.customer?.id}`}>
                            #{disposal.data.customer?.id}{' '}
                            {disposal.data.customer?.firstName}{' '}
                            {disposal.data.customer?.lastName}
                          </Link>
                        </>
                      ) : (
                        <>extern</>
                      )
                    }
                  />
                  <LabeledItem
                    label="E-Mail Korrespondenz bei Verkauf über Webshop"
                    value={disposal.data.customerEmail}
                  />
                  <LabeledItem
                    label="Point of Sale"
                    value={
                      <Icon
                        name={
                          disposal.data.pointOfSale === 'online'
                            ? 'internet explorer'
                            : 'warehouse'
                        }
                      />
                    }
                  />
                </Section>
              </Grid.Column>
              <Grid.Column>
                <Section header="Bestellpositionen">
                  <Table>
                    <Table.Body>
                      {disposal.data?.articleDisposals?.map(
                        (articleDisposal) => (
                          <Table.Row key={articleDisposal.id}>
                            <Table.Cell>
                              {articleDisposal.article.name}
                            </Table.Cell>
                            <Table.Cell>
                              <Currency value={articleDisposal.amount} />
                            </Table.Cell>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                </Section>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              <Grid.Column>
                <Section header="Bezahlung / Rechnung">
                  {disposal.data.invoice && (
                    <Grid>
                      <Grid.Row stretched>
                        <Grid.Column width={8}>
                          <LabeledItem
                            label="Bezahlstatus"
                            value={
                              <PaymentStatus
                                status={disposal.data.invoice?.paymentStatus}
                              />
                            }
                          />
                        </Grid.Column>
                        <Grid.Column>
                          {disposal.data.invoice?.paymentStatus === 'OPEN' && (
                            <LabeledItem
                              label="Aktion"
                              value={
                                <Button
                                  positive
                                  size="massive"
                                  onClick={markOrderAsPayed}
                                >
                                  Geldeingang
                                </Button>
                              }
                            />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}
                  <LabeledItem
                    label="Bezahlart"
                    value={<PaymentType type={disposal.data.paymentType} />}
                  />
                  {disposal.data?.invoice?.payPalTransaction && (
                    <>
                      <LabeledItem
                        label="PayPal Transaktion erstellt am"
                        value={
                          <FormattedDate
                            value={
                              disposal.data?.invoice.payPalTransaction.createdAt
                            }
                          />
                        }
                      />
                      <LabeledItem
                        label="Status der PayPal Transaktion"
                        value={
                          <PayPalTransactionStatus
                            status={
                              disposal.data.invoice?.payPalTransaction?.status
                            }
                            completedAt={
                              disposal.data?.invoice?.payPalTransaction
                                ?.completedAt
                            }
                          />
                        }
                      />

                      <LabeledItem
                        label="PayPal Order ID"
                        value={
                          disposal.data?.invoice.payPalTransaction.payPalOrderId
                        }
                      />
                    </>
                  )}

                  {disposal.data.invoice && (
                    <>
                      <LabeledItem
                        label="Rechnungsnummer"
                        value={disposal.data.invoice.formattedInvoiceNumber}
                      />
                      <LabeledItem
                        label="Download des Beleges als PDF"
                        value={
                          <a
                            href={`${process.env.BACKEND_URL}/admin/disposal/order/${disposal.data.id}/invoice`}
                          >
                            rechnung-
                            {disposal.data.invoice?.formattedInvoiceNumber}.pdf
                          </a>
                        }
                      />
                    </>
                  )}
                  <h4>Rechnungs-Adresse</h4>
                  <LabeledAddress address={disposal.data.invoice?.address} />
                </Section>
              </Grid.Column>
              <Grid.Column>
                <Section header="Versand">
                  {disposal.data.shipping && (
                    <Grid>
                      <Grid.Row stretched>
                        <Grid.Column width={8}>
                          <LabeledItem
                            label="Versandstatus"
                            value={
                              <ShippingStatus
                                status={disposal.data.shipping?.status}
                              />
                            }
                          />
                          {disposal.data.shipping?.status === 'SHIPPED' && (
                            <LabeledItem
                              label="Versandnummer"
                              value={disposal.data.shipping?.shippingCode}
                            />
                          )}
                        </Grid.Column>
                        <Grid.Column width={8}>
                          {disposal.data.shipping.status === 'PREPARING' && (
                            <LabeledItem
                              label="Aktion"
                              value={
                                <>
                                  <Form.Input
                                    type="text"
                                    value={shippingCode}
                                    onChange={(e, data) =>
                                      setShippingCode(data.value)
                                    }
                                    label="Tracking-Link / Versandnummer (als Link!)"
                                  />
                                  <br />
                                  <Button
                                    positive
                                    size="massive"
                                    onClick={markOrderAsShipped}
                                  >
                                    E-Mail mit Versandbestätigung verschicken
                                  </Button>
                                </>
                              }
                            />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}

                  {disposal.data.shippingType === 'ship' && (
                    <>
                      <h4>Liefer-Adresse</h4>
                      <LabeledAddress
                        address={disposal.data.shipping?.address}
                      />
                    </>
                  )}
                  {disposal.data.shippingType === 'self' &&
                    !disposal.data.collected && (
                      <>
                        <p>Die Kundin holt die Ware selbst ab.</p>
                        <LabeledItem
                          label="Aktion"
                          value={
                            <>
                              <Button
                                positive
                                size="massive"
                                onClick={markOrderAsCollected}
                              >
                                Die Ware wurde abgeholt.
                              </Button>
                            </>
                          }
                        />
                      </>
                    )}
                  {disposal.data.shippingType === 'self' &&
                    disposal.data.collected && (
                      <p>Die Ware wurde von der Kundin abgeholt.</p>
                    )}
                </Section>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
    </Page>
  );
};
