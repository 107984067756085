import { axios } from 'core';
import React, {
  ChangeEvent,
  cloneElement,
  createRef,
  memo,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import styles from './uploader.module.scss';

interface Props {
  trigger: ReactElement;
  onUpload?: (file: API.File) => void;
  onChange?: (files: File[]) => Promise<void>;
}

export const Uploader = memo<Props>((props) => {
  const { trigger, onUpload, onChange: onChangeProp } = props;

  const [loading, setLoading] = useState(false);
  const ref = useMemo(() => createRef<HTMLInputElement>(), []);

  const onChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        setLoading(true);

        if (onChangeProp) {
          await onChangeProp(Array.from(event.target.files));
          setLoading(false);
        } else {
          const data = new FormData();

          for (const file of Array.from(event.target.files)) {
            data.append('files', file);
          }

          const response = await axios.post(
            `${process.env.BACKEND_URL}/files`,
            data,
            {
              headers: {
                'Content-Type': 'multipart/formdata;boundary=MultipartBoundary',
              },
            }
          );

          if (response.status === 201 && onUpload) {
            onUpload(response.data[0]);
          }
        }

        setLoading(false);
      }
    },
    [onUpload, onChangeProp]
  );

  const onClick = useCallback(() => {
    if (ref.current && loading === false) {
      ref.current.click();
    }
  }, [ref.current]);

  const element = useMemo(
    () => cloneElement(trigger, { onClick, loading }),
    [trigger, loading]
  );

  return (
    <div className={styles.host}>
      <div className={styles.trigger}>{element}</div>

      <input
        type="file"
        onChange={onChange}
        ref={ref}
        style={{ visibility: 'hidden' }}
      />
    </div>
  );
});
