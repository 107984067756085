import { Endpoint, RemotePaginatedListHook, useEndpoint } from 'core';
import { append, uniq, without } from 'ramda';
import React, {
  cloneElement,
  ComponentType,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  DropdownItemProps,
  Icon,
  Menu,
  Modal,
  ModalProps,
  Segment,
  TransitionablePortal,
} from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

export function useModal<Props>(
  node: ReactElement<ModalProps>
): [ReactElement<ModalProps & Props>, { open: () => void; close: () => void }] {
  const [open, updateOpen] = useState(false);
  const onClose = useCallback(() => updateOpen(false), []);
  const onOpen = useCallback(() => updateOpen(true), []);

  return [
    <TransitionablePortal
      transition={{ animation: 'fade down', duration: 350 }}
      open={open}
    >
      {cloneElement(node, {
        open: true,
        onClose,
        dimmer: 'blurring',
        centered: false,
      })}
    </TransitionablePortal>,
    {
      open: onOpen,
      close: onClose,
    },
  ];
}

type ConfirmHook<Handler extends (...args: any[]) => void> = [
  ReactElement<ModalProps>,
  (question: string, ...args: Parameters<Handler>) => void
];

interface ConfirmState {
  question: string;
  args: any[];
}

interface ConfirmOptions {
  accept?: string;
  cancel?: string;
}

export function useConfirm<Handler extends (...args: any[]) => void>(
  handler: Handler,
  options: ConfirmOptions = { accept: 'Übernehmen', cancel: 'Abbrechen' }
): ConfirmHook<Handler> {
  const [state, setState] = useState<ConfirmState>({
    question: '',
    args: [],
  });

  const ask = useCallback((question: string, ...args: any[]) => {
    setState({
      question,
      args,
    });

    open();
  }, []);

  const onConfirm = useCallback(() => {
    close();
    handler(...state.args);
  }, [state.args]);

  const onCancel = useCallback(() => {
    close();
  }, []);

  const [modal, { open, close }] = useModal(
    <Modal>
      <Modal.Header>
        <Icon name="question circle" />
        Bestätigen
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>{state.question}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={onCancel}
          icon="close"
          labelPosition="right"
          content={options.cancel || 'Abbrechen'}
        />

        <Button
          primary
          onClick={onConfirm}
          icon="chevron right"
          labelPosition="right"
          content={options.accept || 'Übernehmen'}
        />
      </Modal.Actions>
    </Modal>
  );

  return [modal, ask];
}

export function useBackgroundCover(name: string) {
  useLayoutEffect(() => {
    if (document.body.classList.contains('background') === false) {
      document.body.classList.add('background', 'cover');
    }

    if (document.body.classList.contains(name) === false) {
      document.body.classList.add(name);
    }

    return () => {
      document.body.classList.remove(name);
    };
  });
}

interface Tab<Name extends string = string> {
  name: Name;
  icon: SemanticICONS;
  component: ComponentType;
}

export function useTabs<Tabs extends Tab[]>(
  defaultTab: string,
  tabs: Tabs
): [ReactNode, (name: string) => void] {
  const [active, setActive] = useState(defaultTab);
  const Current = useMemo(() => {
    const current = tabs.find((tab) => tab.name === active);

    return current ? current.component : () => <span />;
  }, [active]);

  const node = useMemo(
    () => (
      <>
        <Menu tabular attached="top">
          {tabs.map(({ name, icon }) => (
            <Menu.Item
              key={name}
              active={active === name}
              onClick={() => setActive(name)}
            >
              <Icon name={icon} />
              {name}
            </Menu.Item>
          ))}
        </Menu>
        <Segment attached="bottom">{Current && <Current />}</Segment>
      </>
    ),
    [active, Current]
  );

  return [node, setActive];
}

export interface SelectionApi<T> {
  toggle(item: T, control?: boolean): void;
  add(item: T): void;
  remove(item: T): void;
  reset(initial?: T[]): void;
}

export interface SelectionProps {
  active: boolean;
  onClick(event: MouseEvent<HTMLElement>): void;
}

export type SelectionData<T> = Array<[T, SelectionProps]>;

export type SelectionHook<T> = [SelectionData<T>, T[], SelectionApi<T>];

export function useSelection<T>(
  data: T[],
  initial: T[] = []
): SelectionHook<T> {
  const [selected, setSelected] = useState(initial);
  const toggle = useCallback(
    (item: T, control?: boolean) => {
      if (control) {
        setSelected(
          selected.includes(item)
            ? without([item], selected)
            : append(item, selected)
        );
      } else {
        setSelected([item]);
      }
    },
    [selected]
  );

  const add = useCallback(
    (item: T) => setSelected(uniq(append(item, selected))),
    [selected]
  );

  const remove = useCallback(
    (item: T) => setSelected(without([item], selected)),
    [selected]
  );

  const reset = useCallback((initial?: T[]) => {
    setSelected(initial || []);
  }, []);

  const enhanced: SelectionData<T> = useMemo(
    () =>
      data.map((item) => [
        item,
        {
          active: selected.includes(item),
          onClick: (event: MouseEvent<HTMLElement>) => {
            // event.preventDefault();

            toggle(item, event.metaKey);
          },
        },
      ]),
    [data, selected]
  );

  return [enhanced, selected, { toggle, add, remove, reset }];
}

export function useRemoteSelectOptions<T>(
  endpoint: Endpoint<T>,
  mapper: (data: T) => DropdownItemProps
) {
  const [managedEndpoint, loading] = useEndpoint(endpoint);
  const [data, setData] = useState<T[]>([]);
  const options = useMemo(() => data.map(mapper), [data]);

  useEffect(() => {
    managedEndpoint.get().then((loaded) => setData(loaded));
  }, []);

  return { options, loading };
}

interface UseUploaderOptions<R> {
  accept: string[];
  multiple?: boolean;
  upload: (formData: FormData) => Promise<R>;
}

type PaginationHook = [
  number,
  React.Dispatch<React.SetStateAction<number>>,
  number,
  React.Dispatch<React.SetStateAction<number>>
];

export function usePagination(): PaginationHook {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  return [currentPage, setCurrentPage, totalPages, setTotalPages];
}

interface SortingHook {
  isSorted: (column: string) => 'ascending' | 'descending' | undefined;
  onSortChange: (column: string) => void;
  sortedQuery: any;
}

export function useSorting<T, Q>(
  list: RemotePaginatedListHook<T>,
  query: Q,
  handleSortChange?: () => void
): SortingHook {
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<
    'ascending' | 'descending' | undefined
  >(undefined);

  const isSorted = useCallback(
    (column: string) => {
      return sortColumn === column && sortDirection !== undefined
        ? sortDirection
        : undefined;
    },
    [sortColumn, sortDirection]
  );
  const onSortChange = useCallback(
    (column: string) => {
      const newSortDirection =
        sortDirection === 'ascending' ? 'descending' : 'ascending';
      setSortColumn(column);
      setSortDirection(newSortDirection);

      list.load({
        ...query,
        skip: 0,
        orderBy: column,
        order: newSortDirection === 'descending' ? 'DESC' : 'ASC',
      });
      if (handleSortChange) {
        handleSortChange();
      }
    },
    [sortDirection, query, handleSortChange]
  );

  useEffect(() => {
    list.load({
      ...query,
      orderBy: sortColumn,
      order: sortDirection === 'descending' ? 'DESC' : 'ASC',
    });
  }, []);

  return {
    isSorted,
    onSortChange,
    sortedQuery: {
      ...query,
      orderBy: sortColumn,
      order: sortDirection === 'descending' ? 'DESC' : 'ASC',
    },
  };
}

export function useUploader<R>(
  accept: string[],
  field: string,
  multiple: boolean = false
): [(upload: (data: FormData) => void) => void, boolean] {
  const [callback, setCallback] = useState<(data: FormData) => void>();

  const [loading, setLoading] = useState(false);
  const onChange = useCallback(
    async (event: Event) => {
      const target = event.target as HTMLInputElement;

      if (target.files && target.files.length > 0 && callback) {
        setLoading(true);

        const data = new FormData();

        for (const file of Array.from(target.files)) {
          data.set(field, file);
        }
        try {
          await callback(data);
        } catch (error) {
          // tslint:disable-next-line:no-console
          console.log(error);
        }

        setLoading(false);
      }
    },
    [callback]
  );

  useEffect(() => {
    if (callback) {
      const input = document.createElement('input');
      input.type = 'file';
      input.multiple = multiple;
      input.addEventListener('change', onChange);
      input.accept = accept.join(',');
      input.click();
    }
  }, [callback]);

  const open = useCallback((callback) => {
    setCallback(() => callback);
  }, []);

  return [open, loading];
}

export const Key = Object.freeze({
  KEY_CANCEL: 3,
  KEY_HELP: 6,
  KEY_BACK_SPACE: 8,
  KEY_TAB: 9,
  KEY_CLEAR: 12,
  KEY_ENTER: 13,
  KEY_SHIFT: 16,
  KEY_CONTROL: 17,
  KEY_ALT: 18,
  KEY_PAUSE: 19,
  KEY_CAPS_LOCK: 20,
  KEY_ESCAPE: 27,
  KEY_SPACE: 32,
  KEY_PAGE_UP: 33,
  KEY_PAGE_DOWN: 34,
  KEY_END: 35,
  KEY_HOME: 36,
  KEY_LEFT: 37,
  KEY_UP: 38,
  KEY_RIGHT: 39,
  KEY_DOWN: 40,
  KEY_PRINTSCREEN: 44,
  KEY_INSERT: 45,
  KEY_DELETE: 46,
  KEY_0: 48,
  KEY_1: 49,
  KEY_2: 50,
  KEY_3: 51,
  KEY_4: 52,
  KEY_5: 53,
  KEY_6: 54,
  KEY_7: 55,
  KEY_8: 56,
  KEY_9: 57,
  KEY_SEMICOLON: 59,
  KEY_EQUALS: 61,
  KEY_A: 65,
  KEY_B: 66,
  KEY_C: 67,
  KEY_D: 68,
  KEY_E: 69,
  KEY_F: 70,
  KEY_G: 71,
  KEY_H: 72,
  KEY_I: 73,
  KEY_J: 74,
  KEY_K: 75,
  KEY_L: 76,
  KEY_M: 77,
  KEY_N: 78,
  KEY_O: 79,
  KEY_P: 80,
  KEY_Q: 81,
  KEY_R: 82,
  KEY_S: 83,
  KEY_T: 84,
  KEY_U: 85,
  KEY_V: 86,
  KEY_W: 87,
  KEY_X: 88,
  KEY_Y: 89,
  KEY_Z: 90,
  KEY_LEFT_CMD: 91,
  KEY_RIGHT_CMD: 93,
  KEY_CONTEXT_MENU: 93,
  KEY_NUMPAD0: 96,
  KEY_NUMPAD1: 97,
  KEY_NUMPAD2: 98,
  KEY_NUMPAD3: 99,
  KEY_NUMPAD4: 100,
  KEY_NUMPAD5: 101,
  KEY_NUMPAD6: 102,
  KEY_NUMPAD7: 103,
  KEY_NUMPAD8: 104,
  KEY_NUMPAD9: 105,
  KEY_MULTIPLY: 106,
  KEY_ADD: 107,
  KEY_SEPARATOR: 108,
  KEY_SUBTRACT: 109,
  KEY_DECIMAL: 110,
  KEY_DIVIDE: 111,
  KEY_F1: 112,
  KEY_F2: 113,
  KEY_F3: 114,
  KEY_F4: 115,
  KEY_F5: 116,
  KEY_F6: 117,
  KEY_F7: 118,
  KEY_F8: 119,
  KEY_F9: 120,
  KEY_F10: 121,
  KEY_F11: 122,
  KEY_F12: 123,
  KEY_F13: 124,
  KEY_F14: 125,
  KEY_F15: 126,
  KEY_F16: 127,
  KEY_F17: 128,
  KEY_F18: 129,
  KEY_F19: 130,
  KEY_F20: 131,
  KEY_F21: 132,
  KEY_F22: 133,
  KEY_F23: 134,
  KEY_F24: 135,
  KEY_NUM_LOCK: 144,
  KEY_SCROLL_LOCK: 145,
  KEY_COMMA: 188,
  KEY_PERIOD: 190,
  KEY_SLASH: 191,
  KEY_BACK_QUOTE: 192,
  KEY_OPEN_BRACKET: 219,
  KEY_BACK_SLASH: 220,
  KEY_CLOSE_BRACKET: 221,
  KEY_QUOTE: 222,
  KEY_META: 224,
});

type KeyBindings = {
  [shortcut: string]: () => void;
};

export function useKeybindings(bindings: KeyBindings, deps: any[] = []) {
  const table = useMemo(() => {
    const shortcuts = Object.keys(bindings);

    return shortcuts.map((shortcut) => {
      const keys = shortcut.split('+').map((value) => value.toUpperCase());
      const key = keys.find(
        (value) => ['CTRL', 'ALT', 'SHIFT', 'META'].includes(value) === false
      );

      if (key === undefined) {
        throw new Error(
          `A key binding must include a normal character or numeric key`
        );
      }

      return {
        ctrl: keys.includes('CTRL'),
        alt: keys.includes('ALT'),
        shift: keys.includes('SHIFT'),
        meta: keys.includes('META'),
        key: Key[`KEY_${key}` as keyof typeof Key],
        name: shortcut,
      };
    });
  }, []);

  const onKeypress = useCallback((event: KeyboardEvent) => {
    const shortcut = table.find(({ ctrl, alt, shift, meta, key }) => {
      const isCtrl = (ctrl && event.ctrlKey) || (!ctrl && !event.ctrlKey);
      const isAlt = (alt && event.altKey) || (!alt && !event.altKey);
      const isShift = (shift && event.shiftKey) || (!shift && !event.shiftKey);
      const isMeta = (meta && event.metaKey) || (!meta && !event.metaKey);
      const isKey =
        event.key.toUpperCase().charCodeAt(0) === key || event.keyCode === key;

      return isCtrl && isAlt && isShift && isMeta && isKey;
    });

    if (shortcut) {
      event.stopPropagation();
      event.preventDefault();
      bindings[shortcut.name]();
    }
  }, deps);

  useLayoutEffect(() => {
    window.removeEventListener('keypress', onKeypress);
    window.addEventListener('keypress', onKeypress);

    return () => {
      window.removeEventListener('keypress', onKeypress);
    };
  }, deps);
}
