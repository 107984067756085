import { PaginationAPI, RemotePaginatedListHook } from 'core';
import React, { ComponentType } from 'react';
import { Pagination, PaginationProps } from 'semantic-ui-react';

interface Props {
  list: RemotePaginatedListHook<any>;
  pagination: PaginationAPI;
}
export const PaginationBar: ComponentType<Props> = ({ list, pagination }) => {
  return (
    <Pagination
      activePage={pagination.currentPage + 1}
      onPageChange={(e: any, data: PaginationProps) => {
        let num = 1;
        if (data.activePage) {
          if (typeof data.activePage === 'number') {
            num = data.activePage;
          } else {
            num = parseInt(data.activePage, 10);
          }
        }
        pagination.setCurrentPage(num - 1);
      }}
      totalPages={
        pagination.totalCount
          ? Math.ceil(pagination.totalCount / pagination.take)
          : 1
      }
      ellipsisItem={null}
    />
  );
};
