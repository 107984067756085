import { onApplicationMenu, onTopLevelRoutes } from 'core';
import Router from './components/Router';

onTopLevelRoutes(() => ({
  path: '/reservation',
  secure: true,
  component: Router,
}));

onApplicationMenu(() => ({
  group: 'business',
  label: 'reservations',
  icon: 'star',
  to: '/reservation',
}));
