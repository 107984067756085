import React, { memo } from 'react';
import { Label } from 'semantic-ui-react';

export interface PaymentStatusProps {
  status?: API.PaymentStatus;
}

export const PaymentStatus = memo<PaymentStatusProps>(({ status }) => {
  if (status === 'OPEN') {
    return <Label color="red">Offen</Label>;
  }
  if (status === 'PAYED') {
    return <Label color="green">Bezahlt</Label>;
  }
  if (status === 'WAITING_FOR_PAYPAL') {
    return <Label color="orange">Warten auf PayPal</Label>;
  }

  return null;
});
