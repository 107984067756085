import React, { memo } from 'react';
import { Label } from 'semantic-ui-react';

export interface ShippingStatusProps {
  status?: API.ShippingStatus;
  paymentStatus?: API.PaymentStatus;
}

export const ShippingStatus = memo<ShippingStatusProps>(
  ({ status, paymentStatus }) => {
    if (status === 'PREPARING' && paymentStatus !== 'PAYED') {
      return <Label color="orange">In Vorbereitung</Label>;
    }
    if (status === 'PREPARING')
      return <Label color="red">Warten auf Versand</Label>;
    if (status === 'SHIPPED') {
      return <Label color="green">Versandt</Label>;
    }

    return null;
  }
);
