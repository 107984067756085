import { createEndpoint, useEndpoint, useTranslator } from 'core';
import { format, startOfMonth } from 'date-fns';
import React, {
  ComponentType,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Icon, Menu, Table } from 'semantic-ui-react';
import { DataTable, FormattedDate, Page, PaymentType } from 'shared/components';
import { PaymentStatus } from 'shared/components/PaymentStatus';
import { ShippingStatus } from 'shared/components/ShippingStatus';
import { useDisposalFilter } from './DisposalFilter';

const DisposalDataTable: ComponentType = () => {
  const _ = useTranslator();
  const exportEndpoint = createEndpoint<API.DisposalReportExport>(
    'admin/disposal/disposal/export'
  );
  const [storeEndpoint] = useEndpoint<API.Store>(createEndpoint('stores'));
  const [stores, setStores] = useState<API.Store[]>([]);

  const [filter, { list, query }] = useDisposalFilter(
    {
      orderBy: 'disposal.createdAt',
      order: 'DESC',
      startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      endDate: undefined,
      articleOwner: undefined,
      disposalStore: undefined,
      unpayed: false,
    },
    stores
  );

  const onExport = useCallback(async () => {
    const result = await exportEndpoint.custom({
      method: 'get',
      responseType: 'blob',
      params: query,
    });
    const file = new Blob([result], { type: 'text/csv' });
    window.open(URL.createObjectURL(file));
  }, [query]);

  useEffect(() => {
    storeEndpoint.get().then((response) => setStores(response));
  }, []);

  return (
    <Page
      header={_('menu.orders')}
      loading={list.loading}
      actions={
        <>
          <Menu.Item onClick={onExport}>
            <Icon name="file" />
            CSV Export
          </Menu.Item>
        </>
      }
    >
      {filter}
      <DataTable<API.Disposal>
        query={query}
        list={list}
        rowComponent={({ data, ...props }) => (
          <Table.Row {...props}>
            <Table.Cell>
              <Link to={`/disposal/order/${data.id}`}>{data.id}</Link>
            </Table.Cell>

            <Table.Cell>
              <FormattedDate value={data.createdAt} />
            </Table.Cell>

            <Table.Cell>
              <Icon
                name={
                  data.pointOfSale === 'online'
                    ? 'internet explorer'
                    : 'warehouse'
                }
              />
            </Table.Cell>
            <Table.Cell>{data.store.name}</Table.Cell>

            <Table.Cell>
              <PaymentType type={data.paymentType} />
            </Table.Cell>
            <Table.Cell>
              {data.invoice?.formattedInvoiceNumber}{' '}
              <PaymentStatus status={data.invoice?.paymentStatus} />
            </Table.Cell>
            <Table.Cell>
              <ShippingStatus status={data.shipping?.status} />
            </Table.Cell>
            <Table.Cell>
              {data.customer
                ? `${data.customer.firstName} ${data.customer.lastName}`
                : ''}
            </Table.Cell>
          </Table.Row>
        )}
      >
        {(sorting: any) => (
          <Table.Row>
            <Table.HeaderCell
              sorted={sorting.isSorted('disposal.id')}
              onClick={() => sorting.onSortChange('disposal.id')}
              content="Verkauf"
            />
            <Table.HeaderCell
              sorted={sorting.isSorted('disposal.createdAt')}
              onClick={() => sorting.onSortChange('disposal.createdAt')}
              content="Datum"
            />

            <Table.HeaderCell
              sorted={sorting.isSorted('disposal.pointOfSale')}
              onClick={() => sorting.onSortChange('disposal.pointOfSale')}
              content="Point Of Sale"
            />
            <Table.HeaderCell
              sorted={sorting.isSorted('store.name')}
              onClick={() => sorting.onSortChange('store.name')}
              content="Verkaufsort"
            />
            <Table.HeaderCell
              content="Zahlungsart"
              sorted={sorting.isSorted('disposal.paymentType')}
              onClick={() => {
                sorting.onSortChange('disposal.paymentType');
              }}
            />
            <Table.HeaderCell content="Rechnung" />
            <Table.HeaderCell content="Versand" />

            <Table.HeaderCell
              content="Kundin"
              sorted={sorting.isSorted('customer.lastName')}
              onClick={() => sorting.onSortChange('customer.lastName')}
            />
          </Table.Row>
        )}
      </DataTable>
    </Page>
  );
};

export default memo(DisposalDataTable);
