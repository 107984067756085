import { createEndpoint, RemoteSingleHook, useRemoteSingle } from 'core';
import { Formik, FormikConfig } from 'formik';
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Form as SemanticForm, Icon, Menu } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { ObjectSchema } from 'yup';
import { Page } from './Page';

interface Props<Values extends {}, Entity extends { id: number }> {
  title: string;
  submenu?: ReactNode;
  icon?: SemanticICONS;
  loading?: boolean;
  endpoint: string;
  id?: number;
  initialValues?: Values;
  validationSchema?: ObjectSchema<Values>;
  method?: 'put' | 'post';
  onSubmit?: (
    values: Values,
    remoteSingle: RemoteSingleHook<Entity>
  ) => Promise<any>;
  onCancel?: () => void;
  onSave?: () => void;
  renderActions?: () => ReactNode;
  children: FormikConfig<Values>['children'];
}

export function Editor<FormData, Entity extends { id: number }>({
  title,
  icon,
  endpoint: url,
  submenu,
  loading: forceLoading,
  id,
  onSubmit: passedOnSubmit = async (data, endpoint) => {
    if (id !== undefined) {
      await endpoint.put(id, data as any);
    } else {
      await endpoint.post(data as any);
    }
  },
  children,
  onCancel,
  onSave,
  initialValues: passedInitialValues,
  renderActions,
  validationSchema,
}: PropsWithChildren<Props<FormData, Entity>>) {
  const entity = useRemoteSingle(createEndpoint<Entity>(url));
  const [initialValues, setInitialValues] = useState<FormData | undefined>(
    passedInitialValues
  );

  const history = useHistory();
  const onStdCancelOrSave = useCallback(() => {
    history.goBack();
  }, []);

  const onSubmit = useCallback(
    async (values: FormData) => {
      try {
        await passedOnSubmit(values, entity);

        if (onSave) {
          onSave();
        } else {
          onStdCancelOrSave();
        }
      } catch (error) {
        /* tslint:disable-next-line */
        console.log(error);
      }
    },
    [entity]
  );

  useLayoutEffect(() => {
    if (id !== undefined) {
      entity.load(id);
    }
  }, [id]);

  useEffect(() => {
    if (entity.data) {
      setInitialValues(entity.data as any as FormData);
    }
  }, [entity.data]);

  useEffect(() => {
    setInitialValues(passedInitialValues);
  }, [passedInitialValues]);

  return (
    <Formik
      initialValues={initialValues || ({} as any as FormData)}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formik) => {
        const { isSubmitting, isValid, submitForm } = formik;

        return (
          <Page
            submenu={submenu}
            loading={entity.loading || forceLoading}
            header={title}
            actions={
              <>
                <Menu.Item disabled={isValid === false} onClick={submitForm}>
                  <Icon name="disk" />
                  Speichern
                </Menu.Item>
                <Menu.Item onClick={onCancel ? onCancel : onStdCancelOrSave}>
                  <Icon name="cancel" />
                  Abbrechen
                </Menu.Item>
                {renderActions && renderActions()}
              </>
            }
          >
            <SemanticForm size="small">
              {typeof children === 'function'
                ? (children as any)(formik)
                : children}
            </SemanticForm>
          </Page>
        );
      }}
    </Formik>
  );
}
