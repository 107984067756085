import { onApplicationMenu, onTopLevelRoutes } from 'core';
import { Router } from './components/Router';

onTopLevelRoutes(() => ({
  path: '/customer',
  secure: true,
  component: Router,
}));

onApplicationMenu(() => ({
  group: 'stock',
  label: 'customers',
  icon: 'user',
  to: '/customer',
}));
