import { useTranslator } from 'core';
import React, { ComponentType, lazy } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import StoreEditor from './StoreEditor';

const StoreDataTable = lazy(() => import('./StoreDataTable'));

const Router: ComponentType = () => {
  const history = useHistory();
  const _ = useTranslator();
  return (
    <Switch>
      <Route exact path={'/store'}>
        <Redirect to="/store/list" />
      </Route>

      <Route path="/store/list">
        <StoreDataTable />
      </Route>

      <Route path="/store/:id/edit">
        <StoreEditor />
      </Route>
    </Switch>
  );
};

export default Router;
