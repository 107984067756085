import React, { forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import styles from './markdown-editor.module.scss';

export interface MarkdownEditorProps {
  value?: string;
  name?: string;
  onChange?: (e: any) => void;
  error?: any;
  label?: string | null;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

export const MarkdownEditor = forwardRef<
  HTMLTextAreaElement,
  MarkdownEditorProps
>((props, ref) => {
  const { error, label, ...rest } = props;
  const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>(
    'write'
  );

  return (
    <div className={styles.host}>
      {label && (
        <label>
          {label}
          {props.required && ' *'}
        </label>
      )}
      <ReactMde
        {...rest}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
        }
      />
      {error && <p className="ui error">{error}</p>}
    </div>
  );
});
