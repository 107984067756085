import { array, hook, reducer } from 'apere';
import { ComponentType } from 'react';
import { SemanticICONS } from 'semantic-ui-react';

type TopLevelRoutesPlugin = () => TopLevelRoute;

export interface TopLevelRoute {
  path: string;
  secure?: boolean;
  pageFallback?: boolean;
  component: ComponentType;
}

const [getTopLevelRoutes, onTopLevelRoutes] = hook<
  TopLevelRoutesPlugin,
  TopLevelRoute[]
>(array());

type ApplicationMenuPlugin = () => ApplicationMenuItem;

export interface ApplicationMenuItem {
  group: string;
  to?: string;
  position?: number;
  label: string;
  icon: SemanticICONS;
  policies?: string[];
}

const [getApplicationMenu, onApplicationMenu] = hook<
  ApplicationMenuPlugin,
  ApplicationMenuItem[]
>(array());

export interface SubMenuItem extends Omit<ApplicationMenuItem, 'group'> {}

export type SubmenuPlugin = (to: string) => SubMenuItem[] | undefined;

const [getSubMenu, onSubMenu] = hook<SubmenuPlugin, SubMenuItem[]>(
  reducer([] as SubMenuItem[], (seed, current) => [
    ...seed,
    ...(current || []),
  ]),
);

export {
  getTopLevelRoutes,
  onTopLevelRoutes,
  getApplicationMenu,
  onApplicationMenu,
  onSubMenu,
  getSubMenu,
};
