import React, { useMemo } from 'react';
import {
  Form,
  FormFieldProps as SemanticFormFieldProps,
  Input,
  Dropdown,
} from 'semantic-ui-react';
import { connect } from 'formik';

export interface AmountFormFieldProps {
  name: string;
}

export const AmountFormField = connect<
  SemanticFormFieldProps & AmountFormFieldProps,
  any
>(({ name, label, formik: { setFieldValue, values, errors }, ...props }) => {
  const options = useMemo(
    () => [
      { key: 'net', text: 'Netto', value: 'net' },
      { key: 'gross', text: 'Brutto', value: 'gross' },
    ],
    [],
  );

  if (values[name] === undefined) {
    return null;
  }

  return (
    <Form.Field {...props} error={errors[`${name}.value`] !== undefined}>
      <label>{label}</label>
      <Input
        onChange={(_, { value }) => setFieldValue(`${name}.value`, value)}
        value={values[name].value || ''}
        label={
          <Dropdown
            options={options}
            value={values[name].type || 'net'}
            onChange={(_, { value }) => setFieldValue(`${name}.type`, value)}
          />
        }
      />
    </Form.Field>
  );
});
