import React, { memo } from 'react';
import { Icon, Popup, SemanticICONS } from 'semantic-ui-react';

export interface CurrencyProps {
  value: number;
  originalValue?: number;
}

export const Currency = memo<CurrencyProps>(({ value, originalValue }) => {
  let icon: SemanticICONS | undefined = undefined;
  if (originalValue) {
    if (value < originalValue) {
      icon = 'long arrow alternate down';
    } else if (value > originalValue) {
      icon = 'long arrow alternate up';
    }
  }

  return (
    <>
      {(value * 0.01)
        .toFixed(2)
        .toString()
        .replace('.', ',')}{' '}
      €
      {icon && originalValue !== undefined && (
        <Popup
          content={
            'Runtergesetzt von ' +
            (originalValue * 0.01)
              .toFixed(2)
              .toString()
              .replace('.', ',') +
            ' €'
          }
          trigger={
            <Icon style={{ marginLeft: '1em' }} bordered circular name={icon} />
          }
        />
      )}
    </>
  );
});
