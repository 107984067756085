import { onApplicationMenu, onTopLevelRoutes } from 'core';
import Router from './components/Router';

onTopLevelRoutes(() => ({
  path: '/disposal',
  secure: true,
  component: Router,
}));

onApplicationMenu(() => ({
  group: 'business',
  label: 'disposals',
  icon: 'euro',
  to: '/disposal/article-disposal',
}));

onApplicationMenu(() => ({
  group: 'business',
  label: 'orders',
  icon: 'euro',
  to: '/disposal/order',
}));
