import { createEndpoint, useEndpoint, useTranslator } from 'core';
import React, { ComponentType, memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Input, Menu, Table } from 'semantic-ui-react';
import { DataTable, Page } from 'shared/components';
import { useListFilter } from 'shared/hooks';
interface CustomerFilter {
  store?: number;
  term?: string;
}

export interface CustomerDataTableProps {
  query?: { [key: string]: any };
}

const CustomerDataTable: ComponentType<CustomerDataTableProps> = ({}) => {
  const _ = useTranslator();
  const [storeEndpoint] = useEndpoint<API.Store>(createEndpoint('stores'));

  const [stores, setStores] = useState<API.Store[]>([]);

  useEffect(() => {
    storeEndpoint.get().then((response) => setStores(response));
  }, []);

  const [filter, { list, query }] = useListFilter<
    CustomerFilter & { take?: number },
    API.Customer
  >(
    ({ onChange, values }) => (
      <>
        <Menu.Item>
          <Input
            placeholder="Suche"
            onChange={(e) => onChange('term', e.target.value)}
            value={values.term ? values.term : ''}
          />
        </Menu.Item>
        <Menu.Item>
          <Dropdown
            value={values.store ? values.store : ''}
            clearable
            placeholder="Store"
            selection
            onChange={(e: any, { value }) => {
              onChange('store', value);
            }}
            options={stores.map((s) => ({ text: s.name, value: s.id }))}
          />
        </Menu.Item>
      </>
    ),
    {
      endpointUrl: 'admin/customer',
      initialState: {
        take: 100,
      },
    }
  );

  return (
    <Page header={_('menu.customers')}>
      {filter}
      <DataTable<API.Customer>
        list={list}
        query={query}
        rowComponent={({ data, ...props }) => (
          <Table.Row {...props}>
            <Table.Cell>
              <Link to={`${data.id}/edit`}>{data.id}</Link>
            </Table.Cell>
            <Table.Cell>
              <Link to={`${data.id}/edit`}>
                {data.firstName} {data.lastName}
              </Link>
            </Table.Cell>
            <Table.Cell>{data.email}</Table.Cell>
            <Table.Cell>{data.city}</Table.Cell>
            <Table.Cell>
              {data.authorizations
                ? data.authorizations.map((a) => a.store.name).join(', ')
                : ''}
            </Table.Cell>
          </Table.Row>
        )}
      >
        {(sorting: any) => (
          <Table.Row>
            <Table.HeaderCell
              sorted={sorting.isSorted('customer.id')}
              onClick={() => sorting.onSortChange('customer.id')}
              content="ID"
            ></Table.HeaderCell>
            <Table.HeaderCell
              sorted={sorting.isSorted('customer.lastName')}
              onClick={() => sorting.onSortChange('customer.lastName')}
              content="Kundin"
            />
            <Table.HeaderCell
              sorted={sorting.isSorted('customer.email')}
              onClick={() => sorting.onSortChange('customer.email')}
              content="E-Mail"
            />
            <Table.HeaderCell
              sorted={sorting.isSorted('customer.city')}
              onClick={() => sorting.onSortChange('customer.city')}
              content="Stadt"
            />
            <Table.HeaderCell>Authorisierungen</Table.HeaderCell>
          </Table.Row>
        )}
      </DataTable>
    </Page>
  );
};

export default memo(CustomerDataTable);
