import React, {
  ComponentType,
  memo,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { DropdownProps, FormSelectProps, FormSelect } from 'semantic-ui-react';
import { useEndpoint, createEndpoint } from 'core';

interface Props extends Omit<FormSelectProps, 'options'> {
  as?: ComponentType<DropdownProps> | ComponentType<FormSelectProps>;
  tag: string;
  mimeType: string[];
}

export const FileSelect = memo<Props>(
  ({ as: RenderAs = FormSelect, tag, mimeType, ...selectProps }) => {
    const [endpoint, loading] = useEndpoint(createEndpoint<API.File>('files'));
    const [data, setData] = useState<API.File[]>([]);
    const options = useMemo(
      () =>
        data.map(file => ({
          key: file.id,
          value: file.id,
          text: file.name,
          image: `${process.env.BACKEND_URL}/files/${file.id}`,
        })),
      [data],
    );

    useEffect(() => {
      endpoint
        .get({ tag, mimeType: mimeType.join(',') })
        .then(response => setData(response));
    }, [tag]);

    return <RenderAs {...selectProps} options={options} loading={loading} />;
  },
);
