import { axios } from 'core';
import React, {
  ComponentType,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom';

let user: API.User | undefined;
let setUser: Dispatch<SetStateAction<API.User | undefined>>;

/**
 * Performs a login.
 * On success, the jwt token is automatically stored using `setAuthToken`.
 *
 * @param email Email.
 * @param password Password.
 */
export async function login(email: string, password: string) {
  try {
    const response = await axios.post<string>(
      `${process.env.BACKEND_URL}/auth/login`,
      {
        email,
        password,
      }
    );
    if (response.status === 201) {
      //setSessionToken(response.data);
      await refreshSessionUser();
    }
  } catch (error) {
    throw error;
  }
}

/**
 * Performs a logout which deletes the locally stored auth token and user.
 */
export async function logout() {
  await clearSession();
}

export function setSessionUser(data: API.User | undefined) {
  if (user !== data) {
    user = data;

    if (setUser) {
      setUser(data);
    }
  }
}

/**
 * Refreshes the locally loaded session user from the server.
 */
export async function refreshSessionUser() {
  try {
    setSessionUser(
      (await axios.get<API.User>(`${process.env.BACKEND_URL}/auth/profile`))
        .data
    );
  } catch (error) {
    setSessionUser(undefined);
  }

  return user;
}

/**
 * Clears the locally stored auth token.
 */
export async function clearSession() {
  try {
    await axios.post<API.User>(`${process.env.BACKEND_URL}/auth/logout`);
    setSessionUser(undefined);
  } catch (error) {
    setSessionUser(undefined);
  }
}

const SessionUserContext = createContext<API.User | undefined>(undefined);

/**
 * Session user provider.
 * Wrap this around the app component.
 */
export const SessionUserProvider: ComponentType = ({ children }) => {
  const [sessionUser, update] = useState(user);
  setUser = update;

  return (
    <SessionUserContext.Provider value={sessionUser}>
      {children}
    </SessionUserContext.Provider>
  );
};

/**
 * Injects the current session user.
 */
export function useSessionUser(): API.User {
  return useContext(SessionUserContext) as API.User;
}

/**
 * An enhanced route which redirects to /auth when no session user is available.
 */
export const SecureRoute: ComponentType<RouteProps> = (props) => {
  const sessionUser = useSessionUser();
  const location = useLocation();
  const history = useHistory();
  useLayoutEffect(() => {
    if (
      (sessionUser === undefined || sessionUser.type !== 'Administrator') &&
      location.pathname.startsWith('/auth') === false
    ) {
      logout().then(() => {
        history.push('/auth');
      });
    }
  }, [sessionUser, location.pathname]);

  return <Route {...props} />;
};
