import React from 'react';
import { Dropdown, Input, Menu } from 'semantic-ui-react';
import { useListFilter } from 'shared/hooks';

export function useArticleFilter<T extends API.Article>(
  initialQuery: any = {},
  stores: API.Store[],
  small?: boolean
) {
  const filter = useListFilter<{ term?: string; store?: number }, T>(
    ({ onChange, values }) => (
      <>
        <Menu.Item>
          <Dropdown
            value={values.store}
            clearable
            placeholder="Besitzer"
            selection
            onChange={(e: any, { value }) => {
              onChange('store', value);
            }}
            options={stores.map((s) => ({ text: s.name, value: s.id }))}
          />
        </Menu.Item>
        <Menu.Item>
          <Input
            placeholder="Suche"
            onChange={(e) => onChange('term', e.target.value)}
            value={values.term ? values.term : ''}
          />
        </Menu.Item>
      </>
    ),
    {
      endpointUrl: 'admin/article',
      initialState: initialQuery,
    },
    small
  );
  return filter;
}
