import React from 'react';
import { Modal as ModalBase, Header } from 'semantic-ui-react';

const closeIcon = <div className="modal-close" />;

export const Modal = ({ open, onClose, title, children, actions }: any) => (
  <ModalBase open={open} onClose={onClose} closeIcon={closeIcon}>
    <ModalBase.Header>{title}</ModalBase.Header>
    <ModalBase.Content>{children}</ModalBase.Content>
    {actions && <ModalBase.Actions>{actions}</ModalBase.Actions>}
  </ModalBase>
);
