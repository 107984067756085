import React, { memo, PropsWithChildren, Children } from 'react';
import { Dropdown } from 'semantic-ui-react';

const ActionMenuComponent = memo<PropsWithChildren<{}>>(({ children }) => {
  return Children.count(children) > 0 ? (
    <Dropdown icon="bars" direction="left" labeled className="icon">
      <Dropdown.Menu>
        <Dropdown.Header>Aktionen</Dropdown.Header>
        <Dropdown.Divider />
        {children}
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
});

export const ActionMenu = Object.assign(ActionMenuComponent, {
  Item: Dropdown.Item,
});
