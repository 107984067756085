import logo from 'assets/images/logo-kleiderei.png';
import React, { ComponentType, ReactNode, Ref } from 'react';
import { Icon, Image, Menu, Rail, Sticky } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

interface Props {
  actions: ReactNode;
  onMenuClick: () => void;
  loading?: boolean;
  header?: string;
  detail?: string;
  icon?: SemanticICONS;
  submenu?: ReactNode;
  headerRef: Ref<any>;
}

export const AppHeader: ComponentType<Props> = ({
  loading,
  onMenuClick,
  actions,
  header,
  detail: subheader,
  submenu,
  headerRef,
  icon,
}) => {
  return (
    <Rail id="header" internal position="left" attached>
      <Sticky pushing context={headerRef}>
        <Menu attached inverted color="pink" className="mainmenu">
          <Menu.Item icon onClick={onMenuClick}>
            <Icon name="bars" />
          </Menu.Item>
          <Menu.Item>
            <Image as="a" href="/" style={{ width: '60px' }} src={logo} />
          </Menu.Item>
          {header && (
            <Menu.Item
              header
              content={`${header} ${subheader ? `(${subheader})` : ''}`}
            />
          )}

          <Menu.Menu position="right">{loading === false && actions}</Menu.Menu>
        </Menu>
        {submenu && (
          <Menu attached inverted>
            {submenu}
          </Menu>
        )}
      </Sticky>
    </Rail>
  );
};
