import { onTopLevelRoutes, onApplicationMenu } from 'core';
import { lazy } from 'react';

onTopLevelRoutes(() => ({
  path: '/dashboard',
  secure: true,
  component: lazy(() => import('./components/Dashboard')),
}));

onApplicationMenu(() => ({
  group: 'common',
  label: 'dashboard',
  icon: 'dashboard',
  to: '/dashboard',
}));
