import { createEndpoint, useRemoteSingle, useSessionUser } from 'core';
import { Field, FieldProps } from 'formik';
import React, { ComponentType, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Form, Grid } from 'semantic-ui-react';
import { Editor, Section } from 'shared/components';
import { MarkdownEditor } from 'shared/components/markdown-editor/markdown-editor.component';
import { bool, object, string } from 'yup';

interface Props {}

export interface Values {
  name: string;
  isShopCategory: boolean;
  description: string;
}

export const schema = object().shape({
  name: string().required(),
  isShopCategory: bool(),
  description: string().optional().nullable(),
});

export const defaults: any = {
  name: '',
  isShopCategory: false,
  description: '',
};

export const TagEditor: ComponentType<Props> = (props) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const user = useSessionUser();
  const { addToast } = useToasts();
  const tag = useRemoteSingle(createEndpoint<API.Tag>('admin/tags'));

  const backToList = useCallback(() => {
    history.push('/tag');
  }, []);

  const onSave = useCallback(() => {
    addToast('Der Tag wurden gespeichert.', {
      appearance: 'success',
      autoDismiss: true,
    });
    if (!id) {
      backToList();
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      tag.load(parseInt(id)).catch(backToList);
    }
  }, [id]);

  return (
    <Editor
      title={tag.data?.id ? `Tag ${tag.data.name}` : 'Neuer Tag'}
      endpoint="admin/tags"
      initialValues={defaults}
      validationSchema={schema}
      onCancel={backToList}
      onSave={onSave}
      id={id ? parseInt(id) : undefined}
    >
      {(formik) => (
        <Grid columns={2}>
          <Grid.Column>
            <Section header={tag.data?.id ? tag.data.name : 'Neuer Tag'}>
              <Field
                name="name"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['name']}
                    label="Name"
                    {...field}
                  />
                )}
              />

              <Field
                name="description"
                render={({ field }: FieldProps) => (
                  <MarkdownEditor
                    error={formik.errors['description']}
                    label="Beschreibung"
                    {...field}
                    onChange={(value) =>
                      formik.setFieldValue('description', value)
                    }
                  />
                )}
              />

              <Field
                name="isShopCategory"
                render={({ field }: FieldProps) => (
                  <Form.Checkbox
                    label="Als Shop Kategorie verwenden"
                    {...field}
                    checked={field.value}
                    onChange={(e, { checked }) => {
                      formik.setFieldValue('isShopCategory', checked);
                    }}
                    error={formik.errors['isShopCategory']}
                  />
                )}
              />
            </Section>
          </Grid.Column>
          <Grid.Column>
            <Section header="Hinweis">
              <p>
                Tags, die im Namen mit einem # beginnen, werden im Shop als
                Hash-Tags interpretiert.
              </p>
            </Section>
          </Grid.Column>
        </Grid>
      )}
    </Editor>
  );
};
