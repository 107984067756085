import { ITEMS_PER_PAGE, useTranslator } from 'core';
import React from 'react';
import { Link, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { Page } from 'shared/components';
import SubscriptionDataTable from './SubscriptionDataTable';
import { SubscriptionStats } from './SubscriptionStats';

export const Subscriptions = () => {
  const _ = useTranslator();

  const history = useHistory();
  const subscriptions = '/subscription/list';
  const stats = '/subscription/stats';

  return (
    <Page
      header={_('menu.subscriptions')}
      submenu={
        <>
          <Menu.Item
            content="Mitgliedschaften"
            icon="euro"
            as={Link}
            to={subscriptions}
            active={history.location.pathname.endsWith('list')}
          />
          <Menu.Item
            content="Statistik"
            icon="pie chart"
            as={Link}
            to={stats}
            active={history.location.pathname.endsWith('stats')}
          />
        </>
      }
      /* actions={
    <>
      <Menu.Item onClick={onExport}>
        <Icon name="file" />
        CSV Export
      </Menu.Item>
    </>
  } */
    >
      <Switch>
        <Route path={subscriptions}>
          <SubscriptionDataTable query={{ take: ITEMS_PER_PAGE }} />
        </Route>
        <Route path={stats}>
          <SubscriptionStats />
        </Route>
        <Route exact path="/subscription">
          <Redirect to={subscriptions} />
        </Route>
      </Switch>
    </Page>
  );
};
