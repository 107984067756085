import React, { ComponentType, ReactNode } from 'react';
import styles from './labeled-item.module.scss';
interface Props {
  label: ReactNode;
  value: ReactNode;
}
export const LabeledItem: ComponentType<Props> = (props) => {
  const { label, value } = props;
  return (
    <div className={styles.host}>
      <h4>{label}</h4>
      {value}
    </div>
  );
};
