import React, { ComponentType } from 'react';
import { ActionMenu } from 'shared/components';
import { Subscriptions } from './Subscriptions';

const Router: ComponentType = () => {
  return <Subscriptions />;
};

export default Router;
ActionMenu;
