import React, { ComponentType, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TagEditor } from './TagEditor';

const TagDataTable = lazy(() => import('./TagDataTable'));

const Router: ComponentType = () => {
  return (
    <Switch>
      <Route exact path={'/tag'}>
        <Redirect to="/tag/list" />
      </Route>

      <Route path="/tag/list">
        <TagDataTable />
      </Route>

      <Route path="/tag/create">
        <TagEditor />
      </Route>

      <Route path="/tag/:id/edit">
        <TagEditor />
      </Route>
    </Switch>
  );
};

export default Router;
