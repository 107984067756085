import { onApplicationMenu, onTopLevelRoutes } from 'core';
import { Overview } from './overview/Overview';

onTopLevelRoutes(() => ({
  path: '/donation',
  secure: true,
  component: Overview,
}));

onApplicationMenu(() => ({
  group: 'stock',
  label: 'donations',
  icon: 'sync',
  to: '/donation',
}));
