import {
  createEndpoint,
  ITEMS_PER_PAGE,
  useEndpoint,
  useTranslator,
} from 'core';
import React, {
  ComponentType,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useToasts } from 'react-toast-notifications';
import { Table } from 'semantic-ui-react';
import { ActionMenu, DataTable, FormattedDate, Page } from 'shared/components';
import { useReservationFilter } from './Filter';

const ReservationDataTable: ComponentType = () => {
  const _ = useTranslator();
  const [storeEndpoint] = useEndpoint<API.Store>(createEndpoint('stores'));
  const [stores, setStores] = useState<API.Store[]>([]);
  const reservationEndpoint = createEndpoint('admin/reservations');
  const { addToast } = useToasts();
  const [filter, { list, query }] = useReservationFilter<API.Reservation>(
    {
      take: ITEMS_PER_PAGE,
      orderBy: 'reservation.createdAt',
      order: 'DESC',
    },
    stores
  );

  const onCancel = useCallback(async (id: number) => {
    await reservationEndpoint.patch(id, 'cancel');
    addToast(
      'Die Reservierung wurde storniert. Der Artikel ist wieder verfügbar.',
      { autoDismiss: true, appearance: 'success' }
    );
    list.load();
  }, []);

  useEffect(() => {
    storeEndpoint.get().then((response) => setStores(response));
  }, []);
  return (
    <Page
      header={_('menu.reservations')}
      loading={list.loading}
      actions={<></>}
    >
      {filter}
      <DataTable<API.Reservation>
        list={list}
        query={query}
        rowComponent={({ data, ...props }) => (
          <Table.Row {...props} disabled={data.isExpired}>
            <Table.Cell
              positive={!data.isExpired}
              className={!!data.cancelledAt ? 'strikethrough' : undefined}
            >
              {data.id}
            </Table.Cell>
            <Table.Cell
              positive={!data.isExpired}
              className={!!data.cancelledAt ? 'strikethrough' : undefined}
            >
              <FormattedDate value={data.createdAt} />
            </Table.Cell>
            <Table.Cell
              positive={!data.isExpired}
              className={!!data.cancelledAt ? 'strikethrough' : undefined}
            >
              {data.article.name} (#{data.article.id})
            </Table.Cell>
            <Table.Cell
              positive={!data.isExpired}
              className={!!data.cancelledAt ? 'strikethrough' : undefined}
            >
              {data.customer.firstName} {data.customer.lastName} (#
              {data.customer.id})
            </Table.Cell>
            <Table.Cell error={!!data.cancelledAt} positive={!data.isExpired}>
              <FormattedDate value={data.cancelledAt} />
            </Table.Cell>
            <Table.Cell
              positive={!data.isExpired}
              className={!!data.cancelledAt ? 'strikethrough' : undefined}
            >
              <FormattedDate value={data.lentAt} />
            </Table.Cell>
            <Table.Cell textAlign="right">
              <ActionMenu>
                <ActionMenu.Item
                  icon="pencil"
                  text="Stornieren"
                  onClick={() => onCancel(data.id)}
                />

                {/* 
                <ActionMenu.Item
                  icon="trash"
                  text="Löschen"
                  onClick={event => {
                    event.stopPropagation();
                  }}
                /> */}
              </ActionMenu>
            </Table.Cell>
          </Table.Row>
        )}
      >
        {(sorting: any) => (
          <Table.Row>
            <Table.HeaderCell
              content="ID"
              sorted={sorting.isSorted('reservation.id')}
              onClick={() => sorting.onSortChange('reservation.id')}
            />
            <Table.HeaderCell
              content="Reserviert am"
              sorted={sorting.isSorted('reservation.createdAt')}
              onClick={() => sorting.onSortChange('reservation.createdAt')}
            />
            <Table.HeaderCell
              content="Artikel"
              sorted={sorting.isSorted('reservation.articleId')}
              onClick={() => sorting.onSortChange('reservation.articleId')}
            />
            <Table.HeaderCell
              content="Kundin"
              sorted={sorting.isSorted('reservation.customerId')}
              onClick={() => sorting.onSortChange('reservation.customerId')}
            />
            <Table.HeaderCell
              content="Storniert am"
              sorted={sorting.isSorted('reservation.cancelledAt')}
              onClick={() => sorting.onSortChange('reservation.cancelledAt')}
            />
            <Table.HeaderCell
              content="Ausgeliehen am"
              sorted={sorting.isSorted('reservation.lentAt')}
              onClick={() => sorting.onSortChange('reservation.lentAt')}
            />
            <Table.HeaderCell />
          </Table.Row>
        )}
      </DataTable>
    </Page>
  );
};

export default memo(ReservationDataTable);
