import { createEndpoint, useEndpoint, useTranslator } from 'core';
import { format } from 'date-fns';
import React, { ComponentType, memo, useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Input, Menu, Table } from 'semantic-ui-react';
import { Currency, DataTable, FormattedDate } from 'shared/components';
import { useListFilter } from 'shared/hooks';

interface SubscriptionFilter {
  date: string;
  store?: number;
}

export interface SubscriptionDataTableProps {
  query?: { [key: string]: any };
}

const SubscriptionDataTable: ComponentType<SubscriptionDataTableProps> =
  ({}) => {
    const _ = useTranslator();
    const [storeEndpoint] = useEndpoint<API.Store>(createEndpoint('stores'));
    const exportEndpoint = createEndpoint<API.DisposalReportExport>(
      `admin/subscription/export`
    );
    const [stores, setStores] = useState<API.Store[]>([]);

    useEffect(() => {
      storeEndpoint.get().then((response) => setStores(response));
    }, []);

    const [filter, { list, query }] = useListFilter<
      SubscriptionFilter,
      API.Subscription
    >(
      ({ onChange, values }) => (
        <>
          <Menu.Item>
            <Dropdown
              value={values.store ? values.store : ''}
              clearable
              placeholder="Store"
              selection
              onChange={(e: any, { value }) => {
                onChange('store', value);
              }}
              options={stores.map((s) => ({ text: s.name, value: s.id }))}
            />
          </Menu.Item>
          <Menu.Item>
            <Input
              type="date"
              placeholder="Stichtag"
              onChange={(e) => onChange('date', e.target.value)}
              value={values.date}
            />
          </Menu.Item>
        </>
      ),
      {
        endpointUrl: 'admin/subscription',
        initialState: {
          date: format(new Date(), 'yyyy-MM-dd'),
        },
      }
    );

    return (
      <>
        {filter}
        <Menu>
          <Menu.Item>
            <Button
              icon
              as="a"
              download="export.csv"
              target="_blank"
              href={`${
                process.env.BACKEND_URL
              }/admin/subscription/export?${Object.keys(query)
                .map(
                  (k) =>
                    `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`
                )
                .join('&')}`}
            >
              <Icon name="file" />
              CSV Export
            </Button>
          </Menu.Item>
        </Menu>
        <DataTable<API.Subscription>
          list={list}
          query={query}
          rowComponent={({ data, ...props }) => (
            <Table.Row {...props}>
              <Table.Cell>
                {data.customer
                  ? `${data.customer.firstName} ${data.customer.lastName}`
                  : 'UNBEKANNT'}
              </Table.Cell>
              <Table.Cell>
                {data.customer ? data.customer.email : 'UNBEKANNT'}
              </Table.Cell>
              <Table.Cell>
                <FormattedDate value={data.startAt} />
              </Table.Cell>
              <Table.Cell>
                <Currency value={data.fee} />
              </Table.Cell>
              <Table.Cell>
                <Currency value={data.feeWithDiscount} />
              </Table.Cell>
              <Table.Cell>...</Table.Cell>
            </Table.Row>
          )}
        >
          {(sorting: any) => (
            <Table.Row>
              <Table.HeaderCell
                sorted={sorting.isSorted('customer.lastName')}
                onClick={() => sorting.onSortChange('customer.lastName')}
                content="Kundin"
              />
              <Table.HeaderCell
                sorted={sorting.isSorted('customer.email')}
                onClick={() => sorting.onSortChange('customer.email')}
                content="E-Mail"
              />
              <Table.HeaderCell
                sorted={sorting.isSorted('subscription.startAt')}
                onClick={() => sorting.onSortChange('subscription.startAt')}
                content="Anfangsdatum"
              />
              <Table.HeaderCell
                sorted={sorting.isSorted('subscription.fee')}
                onClick={() => sorting.onSortChange('subscription.fee')}
                content="Gebühr"
              />
              <Table.HeaderCell content="Gebühr (inkl. Rabatte)" />
              <Table.HeaderCell content="Bonus/Rückstand" />
            </Table.Row>
          )}
        </DataTable>
      </>
    );
  };

export default memo(SubscriptionDataTable);
