import React, { memo } from 'react';
import { useTranslator } from 'core';

export interface StatusProps {
  value: string;
}

export const Status = memo<StatusProps>(({ value }) => {
  const _ = useTranslator();

  return <>{_(`status.${value}`)}</>;
});
