import { getSubMenu, SubMenuItem, useSessionUser } from 'core';
import React, {
  ComponentType,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Divider, Icon, Loader, Menu, Segment } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { AppFooter } from './AppFooter';
import { AppHeader } from './AppHeader';
import { AppSidebar } from './AppSidebar';

interface Props {
  loading?: boolean;
  actions?: ReactNode;
  header?: string;
  detail?: string;
  submenu?: ReactNode;
  icon?: SemanticICONS;
  style?: { [key: string]: string };
}

const PageLoader: ComponentType = () => (
  <>
    <Divider hidden />
    <Loader active size="large" inline="centered" />
  </>
);

export const Page: ComponentType<Props> = ({
  children,
  actions,
  header,
  detail,
  icon,
  submenu,
  loading = false,
  style = {},
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const onMenuClick = useCallback(
    () => setMenuVisible((visible) => !visible),
    []
  );

  const user = useSessionUser();
  const location = useLocation();
  const autoSubMenu = useMemo(() => {
    if (submenu) {
      return submenu;
    }

    const filter = (item: SubMenuItem | false) => {
      if (item === false) {
        return false;
      }

      if (user === undefined) {
        return false;
      }

      return true;
    };

    const items = getSubMenu(location.pathname).filter(filter) as SubMenuItem[];

    if (items.length === 0) {
      return undefined;
    }

    return (
      <>
        {items.map((item) => (
          <Menu.Item
            active={location.pathname.endsWith(item.to || '#')}
            key={item.label}
            as={item.to ? Link : Menu.Item}
            to={item.to}
          >
            <Icon name={item.icon} />
            {item.label}
          </Menu.Item>
        ))}
      </>
    );
  }, [submenu, location.pathname, user]);

  const headerRef = useRef<any>();

  const onAnyClick = useCallback(() => {
    setMenuVisible(false);
  }, []);

  return (
    <div
      style={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      ref={headerRef}
    >
      <AppHeader
        onMenuClick={onMenuClick}
        actions={actions}
        loading={loading}
        header={header}
        icon={icon}
        detail={detail}
        submenu={autoSubMenu}
        headerRef={headerRef}
      />
      <AppSidebar visible={menuVisible} hasSubmenu={!!autoSubMenu} id="sidebar">
        <Segment
          basic
          id="content"
          style={{ ...style, ...(autoSubMenu ? { paddingTop: '4em' } : {}) }}
          onClick={onAnyClick}
        >
          {children}
        </Segment>
      </AppSidebar>
      <AppFooter />
    </div>
  );
};
function useRouter() {
  throw new Error('Function not implemented.');
}
