import React from 'react';
import { Checkbox, Dropdown, Menu } from 'semantic-ui-react';
import { useListFilter } from 'shared/hooks';

export function useReservationFilter<T extends API.Reservation>(
  initialQuery: any = {},
  stores: API.Store[]
) {
  const filter = useListFilter<{ store?: number; cancelledOnly: boolean }, T>(
    ({ onChange, values }) => (
      <>
        <Menu.Item>
          <Dropdown
            value={values.store}
            clearable
            placeholder="Filiale"
            selection
            onChange={(e: any, { value }) => {
              onChange('store', value);
            }}
            options={stores.map((s) => ({ text: s.name, value: s.id }))}
          />
        </Menu.Item>
        <Menu.Item>
          <Checkbox
            checked={values.cancelledOnly}
            onChange={(e, { checked }) => {
              onChange('activeOnly', checked);
            }}
            label="Nur aktive"
          />
        </Menu.Item>
        <Menu.Item>
          <Checkbox
            checked={values.cancelledOnly}
            onChange={(e, { checked }) => {
              onChange('cancelledOnly', checked);
            }}
            label="Nur stornierte"
          />
        </Menu.Item>
      </>
    ),
    {
      endpointUrl: 'admin/reservations',
      initialState: initialQuery,
    }
  );
  return filter;
}
