import { useCallback, useMemo, useState } from 'react';
import { RemotePaginatedListHook } from './endpoint';

export interface PaginationAPI {
  take: number;
  setTake(take: number): void;
  skip: number;
  setSkip(skip: number): void;
  totalCount: number;
  numPages: number;
  currentPage: number;
  setCurrentPage(page: number): void;
}
export const usePagination = <T>(
  totalCount: number,
  takeProp: number,
  list: RemotePaginatedListHook<T>,
  parameters?: any
): PaginationAPI => {
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(takeProp);
  const [reloadRequired, setReloadRequired] = useState(false);

  const currentPage = useMemo(() => {
    if (take === 0) {
      return 0;
    }
    return Math.floor(skip / take);
  }, [totalCount, take, skip]);

  const numPages = useMemo(() => {
    if (take === 0) {
      return 0;
    }
    return Math.ceil(totalCount / take);
  }, [totalCount, take]);

  const setCurrentPage = useCallback(
    (page: number) => {
      const newSkip = take * Math.max(0, Math.min(page, numPages));
      setSkip(newSkip);
      list.load({
        ...parameters,
        take,
        skip: newSkip,
      });
    },
    [numPages, take, parameters]
  );

  return {
    take,
    setTake,
    skip,
    setSkip,
    totalCount,
    numPages,
    currentPage,
    setCurrentPage,
  };
};
