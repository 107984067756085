import { useSessionUser, useTranslator } from 'core';
import React, { ComponentType, memo } from 'react';
import { Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { Page } from 'shared/components';
import * as Yup from 'yup';

export interface Values {
  name: string;
  streetAndNumber: string;
  city: string;
  postalCode: string;
  country: string;
  email: string;
  administrativeEmail: string;
  instagramUrl: string;
  agbUrl: string;
  enableLendingOverdueReminder: boolean;
  acceptOnlineOrders: boolean;
}

export const schema = Yup.object({
  name: Yup.string().required().min(3),
  streetAndNumber: Yup.string(),
  city: Yup.string(),
  postalCode: Yup.string(),
  country: Yup.string(),
  email: Yup.string().email(),
  administrativeEmail: Yup.string().email(),
  instagramUrl: Yup.string(),
  agbUrl: Yup.string(),
  enableLendingOverdueReminder: Yup.bool(),
  acceptOnlineOrders: Yup.bool(),
});

export const defaults: any = {
  name: '',
  streetAndNumber: '',
  city: '',
  postalCode: '',
  country: '',
  email: '',
  administrativeEmail: '',
  instagramUrl: '',
  agbUrl: '',
  enableLendingOverdueReminder: false,
  acceptOnlineOrders: false,
};

const StoreSettings: ComponentType = () => {
  const _ = useTranslator();
  const user = useSessionUser();

  return (
    <Page>
      <Message>
        Die Shop-Einstellungen befinden sich jetzt bei den{' '}
        <Link to="store">Filialen</Link>.
      </Message>
    </Page>
  );
};

export default memo(StoreSettings);
