import {
  amountize,
  createEndpoint,
  Endpoint,
  RemotePaginatedListHook,
  useEndpoint,
  useRemotePaginatedList,
} from 'core';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Button, Menu } from 'semantic-ui-react';

interface FilterProps<T> {
  endpointUrl: string;
  initialState: T;
  renderSummary?: boolean | ((items: T[]) => ReactNode);
}

type FilterHook = [
  ReactNode,
  {
    endpoint: Endpoint<any>;
    loading: boolean;
    data: any;
  }
];

type FilterFunction<T> = ({
  onChange,
  values,
}: {
  onChange: (name: string, value: any) => void;
  values: T;
}) => ReactNode;

export function useFilter<T, Q = any>(
  renderFunction: FilterFunction<T>,
  { endpointUrl, initialState, renderSummary = true }: FilterProps<T>
): FilterHook {
  const [endpoint, loading] = useEndpoint<Q>(createEndpoint(endpointUrl));
  const [filter, setFilter] = useState<T>({ ...initialState });
  const [query, setQuery] = useState<T | undefined>(undefined);
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    endpoint.get(query).then((response) => setData(response));
  }, [query]);

  const component = renderFunction({
    values: filter,
    onChange: (name: string, value: string) =>
      setFilter({ ...filter, [name]: value }),
  });
  const summaryComponent = renderSummary ? (
    typeof renderSummary === 'function' ? (
      renderSummary(data)
    ) : (
      <>{amountize(data, 'Ergebnis', 'Ergebnisse')}</>
    )
  ) : undefined;
  const FilterComponent = (
    <Menu>
      <Menu.Item>{component}</Menu.Item>
      <Menu.Item>
        <Button onClick={() => setQuery(filter)}>Filter Anwenden</Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          onClick={() => {
            setQuery({ ...initialState });
            setFilter({ ...initialState });
          }}
        >
          Zurücksetzen
        </Button>
      </Menu.Item>
      {summaryComponent && <Menu.Item>{summaryComponent}</Menu.Item>}
    </Menu>
  );

  return [FilterComponent, { endpoint, loading, data }];
}

// LIST

interface ListFilterProps<T, Q> {
  endpointUrl: string;
  initialState: T;
  renderSummary?:
    | boolean
    | ((paginatedData: { items: Q[]; totalCount: number }) => ReactNode);
}

type ListFilterHook<T, Q> = [
  ReactNode,
  {
    list: RemotePaginatedListHook<Q>;
    query: T;
    reload: () => void;
  }
];
export function useListFilter<T, Q extends API.BaseEntity>(
  renderFunction: FilterFunction<T>,
  { endpointUrl, initialState, renderSummary = true }: ListFilterProps<T, Q>,
  small?: boolean
): ListFilterHook<T, Q> {
  const [filter, setFilter] = useState<T>({ ...initialState });
  const [query, setQuery] = useState<T>({ ...initialState });

  const list = useRemotePaginatedList<Q>(
    createEndpoint(endpointUrl),
    query,
    true
  );

  const component = renderFunction({
    values: filter,
    onChange: (name: string, value: string) =>
      setFilter({ ...filter, [name]: value }),
  });

  const summaryComponent = renderSummary ? (
    typeof renderSummary === 'function' ? (
      renderSummary(list.paginatedData)
    ) : (
      <>{amountize(list.paginatedData.totalCount, 'Ergebnis', 'Ergebnisse')}</>
    )
  ) : undefined;

  const reload = useCallback(() => {
    list.load(query);
  }, [list, query]);

  const FilterComponent = (
    <>
      <Menu size={small ? 'tiny' : undefined}>
        {component}
        <Menu.Item>
          <Button
            onClick={() => {
              setQuery({ ...filter });
            }}
          >
            Filter Anwenden
          </Button>
        </Menu.Item>
        {!small && (
          <Menu.Item>
            <Button
              onClick={() => {
                setQuery({ ...initialState });
                setFilter({ ...initialState });
              }}
            >
              Zurücksetzen
            </Button>
          </Menu.Item>
        )}
      </Menu>
      {summaryComponent && (
        <Menu>
          <Menu.Item>{summaryComponent}</Menu.Item>
        </Menu>
      )}
    </>
  );

  return [FilterComponent, { list, query, reload }];
}
