import React, { ComponentType, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { LabeledItem } from '../labeled-item/labeled-item.component';
import styles from './labeled-address.module.scss';
interface Props {
  address?: API.Address;
}
export const LabeledAddress: ComponentType<Props> = (props) => {
  const [view, setView] = useState(0);
  const { address } = props;

  if (!address) {
    return null;
  }

  return (
    <div className={styles.host}>
      <Button.Group>
        <Button active={view === 0} onClick={() => setView(0)}>
          Normal
        </Button>
        <Button.Or text="/" />
        <Button active={view === 1} onClick={() => setView(1)}>
          Copy & Paste
        </Button>
      </Button.Group>
      <div className={styles.view}>
        {view === 0 && (
          <>
            <LabeledItem label="Firma" value={address.company} />
            <LabeledItem
              label="Name"
              value={`${address.firstName} ${address.lastName}`}
            />
            <LabeledItem
              label="Straße und Hausnummer"
              value={address.streetAndNumber}
            />
            <LabeledItem label="PLZ" value={address.postalCode} />
            <LabeledItem label="Stadt" value={address.city} />
            <LabeledItem label="Land" value={address.country} />
          </>
        )}
        {view === 1 && (
          <code>
            {address.company}
            <br />
            {address.firstName} {address.lastName}
            <br />
            {address.streetAndNumber}
            <br />
            {address.postalCode} {address.city}
            <br />
            {address.country}
          </code>
        )}
      </div>
    </div>
  );
};
