import React, { ComponentType } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ReservationDataTable from './ReservationDataTable';

const Router: ComponentType = () => {
  return (
    <Switch>
      <Route exact path={'/reservation'}>
        <Redirect to="/reservation/list" />
      </Route>

      <Route path="/reservation/list">
        <ReservationDataTable />
      </Route>
    </Switch>
  );
};

export default Router;
