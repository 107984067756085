import {
  createEndpoint,
  ITEMS_PER_PAGE,
  useEndpoint,
  useTranslator,
} from 'core';
import React, {
  ComponentType,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Message, Segment, Table } from 'semantic-ui-react';
import { DataTable, Page } from 'shared/components';
import styles from './article-positions.module.scss';
import { useArticleFilter } from './Filter';
import { SubMenu } from './SubMenu';
const query = {
  take: ITEMS_PER_PAGE,
  onlineArticlesOnly: true,
};
const ArticlePositions: ComponentType = () => {
  const _ = useTranslator();
  const positionsEndpoint = createEndpoint('admin/article/positions');
  const [storeEndpoint] = useEndpoint<API.Store>(createEndpoint('admin/store'));
  const [dirty, setDirty] = useState(false);
  const [articleEndpoint] = useEndpoint<API.InventoryArticle>(
    createEndpoint('admin/article')
  );
  const [sortedArticlesEndpoint] = useEndpoint<API.InventoryArticle>(
    createEndpoint('admin/article')
  );
  const [stores, setStores] = useState<API.Store[]>([]);

  const [filter, { list, query, reload }] =
    useArticleFilter<API.InventoryArticle>(
      {
        take: ITEMS_PER_PAGE,
      },
      stores,
      true
    );

  const [sortedArticlesLocal, setSortedArticlesLocal] = useState<
    API.InventoryArticle[]
  >([]);

  const swap = useCallback(
    (a, b) => {
      const newSortedArticles = [...sortedArticlesLocal];

      newSortedArticles.splice(b, 0, newSortedArticles.splice(a, 1)[0]);

      setSortedArticlesLocal(newSortedArticles);
      setDirty(true);
    },
    [sortedArticlesLocal]
  );

  const onAddToTop = useCallback(
    (article: API.InventoryArticle) => {
      setSortedArticlesLocal([
        article,
        ...sortedArticlesLocal.filter((a) => a.id !== article.id),
      ]);
      setDirty(true);
    },
    [sortedArticlesLocal]
  );

  const onAddToBottom = useCallback(
    (article: API.InventoryArticle) => {
      setSortedArticlesLocal([
        ...sortedArticlesLocal.filter((a) => a.id !== article.id),
        article,
      ]);
      setDirty(true);
    },
    [sortedArticlesLocal]
  );

  const onAddToShop = useCallback(
    async (article: API.InventoryArticle) => {
      await articleEndpoint.patch(article.id, 'add-to-shop');
      await reload();
      onAddToTop(article);
      setDirty(true);
    },
    [onAddToTop]
  );

  const onSave = useCallback(async () => {
    await positionsEndpoint.post(sortedArticlesLocal.map((a) => a.id));
    setDirty(false);
  }, [sortedArticlesLocal]);

  useEffect(() => {
    storeEndpoint.get().then((response) => setStores(response));
    sortedArticlesEndpoint
      .get({
        onlineArticlesOnly: true,
        orderBy: 'article.position',
        order: 'ASC',
      })
      .then((response) => setSortedArticlesLocal(response));
  }, []);

  return (
    <Page header={_('menu.articles')} submenu={<SubMenu />} actions={<></>}>
      <Message>
        <p>
          Die Position aller Artikel, die im Webshop verfügbar sind kann hier
          verändert werden.
        </p>
        <p>
          In der linken Tabelle werden alle Artikel angezeigt, in der rechten
          nur die Artikel, die im Webshop verfügbar sind.
        </p>
        <Grid className={styles.host}>
          <Grid.Row>
            <Grid.Column width={8}>
              {filter}

              <DataTable<API.InventoryArticle>
                list={list}
                query={query}
                rowComponent={({ data, ...props }) => (
                  <Table.Row {...props}>
                    <Table.Cell>#{data.id}</Table.Cell>
                    <Table.Cell>
                      <Link to={`${data.id}/edit`}>{data.name}</Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`${data.id}/edit`}>{data.slug}</Link>
                    </Table.Cell>

                    <Table.Cell>{data.ownerStore?.name}</Table.Cell>
                    <Table.Cell>{data.currentStore?.name}</Table.Cell>

                    <Table.Cell textAlign="right">
                      {data.isAvailableOnline && (
                        <>
                          <Button
                            icon="level up alternate"
                            onClick={() => onAddToTop(data)}
                          />
                          <Button
                            icon="level down alternate"
                            onClick={() => onAddToBottom(data)}
                          />
                        </>
                      )}
                      {!data.isAvailableOnline && (
                        <>
                          <Button
                            icon="internet explorer"
                            onClick={() => onAddToShop(data)}
                          />
                        </>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )}
              >
                {(sorting: any) => (
                  <Table.Row>
                    <Table.HeaderCell
                      content="ID"
                      sorted={sorting.isSorted('article.id')}
                      onClick={() => sorting.onSortChange('article.id')}
                    />
                    <Table.HeaderCell
                      content="Name"
                      sorted={sorting.isSorted('article.name')}
                      onClick={() => sorting.onSortChange('article.name')}
                    />
                    <Table.HeaderCell
                      content="Slug"
                      sorted={sorting.isSorted('article.slug')}
                      onClick={() => sorting.onSortChange('article.slug')}
                    />

                    <Table.HeaderCell
                      content="Besitzer"
                      sorted={sorting.isSorted('article.ownerStore')}
                      onClick={() => sorting.onSortChange('article.ownerStore')}
                    />
                    <Table.HeaderCell
                      content="Aktueller Standort"
                      sorted={sorting.isSorted('article.currentStore')}
                      onClick={() =>
                        sorting.onSortChange('article.currentStore')
                      }
                    />

                    <Table.HeaderCell />
                  </Table.Row>
                )}
              </DataTable>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment>
                <Button color="green" onClick={onSave} disabled={!dirty}>
                  Sortierung speichern
                </Button>
              </Segment>
              <Table>
                <Table.Header>
                  <Table.HeaderCell>Position</Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                </Table.Header>
                {sortedArticlesLocal.map((article, i) => (
                  <Table.Row
                    onDragStart={(e) => e.dataTransfer.setData('index', i)}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      const b = e.dataTransfer.getData('index');
                      swap(parseInt(b, 10), i);
                    }}
                    draggable
                    className={styles.draggableRow}
                    key={article.id}
                  >
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>{article.name}</Table.Cell>
                    <Table.Cell>#{article.id}</Table.Cell>
                  </Table.Row>
                ))}
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Message>
    </Page>
  );
};

export default memo(ArticlePositions);
