import React, { memo } from 'react';
import { Form } from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';

export interface AddressFormProps {
  path: string;
}

export const AddressForm = memo<AddressFormProps>(({ path }) => {
  return (
    <>
      <Field
        name={`${path}.company`}
        render={({ field }: FieldProps) => (
          <Form.Input label="Firma" {...field} />
        )}
      />
      <Form.Group>
        <Field
          name={`${path}.street`}
          render={({ field }: FieldProps) => (
            <Form.Input width={13} label="Straße" {...field} />
          )}
        />
        <Field
          name={`${path}.number`}
          render={({ field }: FieldProps) => (
            <Form.Input width={3} label="Nr." {...field} />
          )}
        />
      </Form.Group>
      <Form.Group>
        <Field
          name={`${path}.postalCode`}
          render={({ field }: FieldProps) => (
            <Form.Input width={3} label="PLZ" {...field} />
          )}
        />
        <Field
          name={`${path}.city`}
          render={({ field }: FieldProps) => (
            <Form.Input width={13} label="Stadt" {...field} />
          )}
        />
      </Form.Group>
      <Form.Group>
        <Field
          name={`${path}.province`}
          render={({ field }: FieldProps) => (
            <Form.Input width={3} label="Bundesland" {...field} />
          )}
        />
        <Field
          name={`${path}.country`}
          render={({ field }: FieldProps) => (
            <Form.Input width={13} label="Land" {...field} />
          )}
        />
      </Form.Group>
      <Form.Group widths={2}>
        <Field
          name={`${path}.phone`}
          render={({ field }: FieldProps) => (
            <Form.Input label="Telefon " {...field} />
          )}
        />
        <Field
          name={`${path}.fax`}
          render={({ field }: FieldProps) => (
            <Form.Input label="Fax" {...field} />
          )}
        />
      </Form.Group>
      <Field
        name={`${path}.email`}
        render={({ field }: FieldProps) => (
          <Form.Input label="E-Mail" type="email" {...field} />
        )}
      />
    </>
  );
});
