import {
  getTopLevelRoutes,
  refreshSessionUser,
  SecureRoute,
  SessionUserProvider,
  Translator,
} from 'core';
import React, { ComponentType, Suspense } from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { Divider, Loader } from 'semantic-ui-react';
import { Page } from 'shared/components/Page';
import './pages/article';
import './pages/auth';
import './pages/customer';
import './pages/dashboard';
import './pages/disposal';
import './pages/donation';
import './pages/reservation';
import './pages/settings';
import './pages/store';
import './pages/subscription';
import './pages/tag';
import './pages/tools';
import './pages/trash';

const topLevelRoutes = getTopLevelRoutes();

const WithPageFallback: ComponentType = ({ children }) => (
  <Suspense fallback={<Page loading />}>{children}</Suspense>
);

const WithSimpleFallback: ComponentType = ({ children }) => (
  <Suspense
    fallback={
      <>
        <Divider hidden />
        <Loader inverted />
      </>
    }
  >
    {children}
  </Suspense>
);

const App: ComponentType = () => {
  return (
    <SessionUserProvider>
      <ToastProvider>
        <Translator>
          <BrowserRouter>
            <Switch>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>

              {topLevelRoutes.map(
                ({ path, secure, pageFallback, component: Component }) => {
                  const Fallback =
                    pageFallback !== false
                      ? WithPageFallback
                      : WithSimpleFallback;

                  return secure === true ? (
                    <SecureRoute key={path} path={path}>
                      <Fallback>
                        <Component />
                      </Fallback>
                    </SecureRoute>
                  ) : (
                    <Route key={path} path={path}>
                      <Fallback>
                        <Component />
                      </Fallback>
                    </Route>
                  );
                }
              )}

              <Route>404</Route>
            </Switch>
          </BrowserRouter>
        </Translator>
      </ToastProvider>
    </SessionUserProvider>
  );
};

refreshSessionUser().then(() => {
  render(<App />, document.getElementById('app'));
});
