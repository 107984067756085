import { createEndpoint, useRemoteSingle, useTranslator } from 'core';
import { Field, FieldProps } from 'formik';
import React, { ComponentType, memo, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Form, Grid } from 'semantic-ui-react';
import { Editor, Section } from 'shared/components';
import * as Yup from 'yup';

export interface Values {
  name: string;
  streetAndNumber: string;
  city: string;
  postalCode: string;
  country: string;
  email: string;
  administrativeEmail: string;
  instagramUrl: string;
  agbUrl: string;
  enableLendingOverdueReminder: boolean;
  acceptOnlineOrders: boolean;
}

export const schema = Yup.object({
  name: Yup.string().required().min(3),
  streetAndNumber: Yup.string(),
  city: Yup.string(),
  postalCode: Yup.string(),
  country: Yup.string(),
  email: Yup.string().email(),
  administrativeEmail: Yup.string().email(),
  instagramUrl: Yup.string(),
  agbUrl: Yup.string(),
  enableLendingOverdueReminder: Yup.bool(),
  acceptOnlineOrders: Yup.bool(),
});

export const defaults: any = {
  name: '',
  streetAndNumber: '',
  city: '',
  postalCode: '',
  country: '',
  email: '',
  administrativeEmail: '',
  instagramUrl: '',
  agbUrl: '',
  enableLendingOverdueReminder: false,
  acceptOnlineOrders: false,
};

const StoreEditor: ComponentType = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const _ = useTranslator();
  const { addToast } = useToasts();
  const store = useRemoteSingle(
    createEndpoint<API.Store>('admin/store')
  );

  const backToList = useCallback(() => {
    history.push('/store');
  }, []);

  const onSave = useCallback(() => {
    addToast('Der Store wurde gespeichert.', {
      appearance: 'success',
      autoDismiss: true,
    });
    location.reload();
    //backToList();
  }, []);

  useEffect(() => {
    store.load(parseInt(id)).catch(backToList);
  }, [id]);
  return (
    <Editor
      title={store.data?store.data.name : 'Neuer Store'}
      endpoint="admin/store"
      initialValues={defaults}
      validationSchema={schema}
      onCancel={backToList}
      onSave={onSave}
      id={store.data?.id}
    >
      {(formik) => (
        <Grid columns={2} divided>
          <Grid.Column>
            <Section header="Allgemein">
              <Field
                name="name"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['name']}
                    label="Name"
                    {...field}
                  />
                )}
              />
              <Field
                name="streetAndNumber"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['streetAndNumber']}
                    label="Straße und Nr."
                    {...field}
                  />
                )}
              />
              <Field
                name="postalCode"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['postalCode']}
                    label="PLZ"
                    {...field}
                  />
                )}
              />
              <Field
                name="city"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['city']}
                    label="Stadt"
                    {...field}
                  />
                )}
              />
              <Field
                name="country"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['country']}
                    label="Land"
                    {...field}
                  />
                )}
              />
              <Field
                name="email"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['email']}
                    label="E-Mail"
                    {...field}
                  />
                )}
              />
              <Field
                name="administrativeEmail"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['administrativeEmail']}
                    label="Administrative E-Mail"
                    {...field}
                  />
                )}
              />
              <Field
                name="instagramUrl"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['instagramUrl']}
                    label="Instagram URL"
                    {...field}
                  />
                )}
              />
              <Field
                name="agbUrl"
                render={({ field }: FieldProps) => (
                  <Form.Input
                    error={formik.errors['agbUrl']}
                    label="AGB PDF Link"
                    {...field}
                  />
                )}
              />
              <Field
                name="acceptOnlineOrders"
                render={({ field }: FieldProps) => {
                  return (
                    <Form.Checkbox
                      error={formik.errors['acceptOnlineOrders']}
                      label="Online-Shop aktiv"
                      checked={field.value}
                      onChange={(_, data) =>
                        formik.setFieldValue('acceptOnlineOrders', data.checked)
                      }
                    />
                  );
                }}
              />
            </Section>
          </Grid.Column>
          <Grid.Column>
            <Section header="Benachrichtigungen">
              <Field
                name="enableLendingOverdueReminder"
                render={({ field }: FieldProps) => {
                  return (
                    <Form.Checkbox
                      error={formik.errors['enableLendingOverdueReminder']}
                      label="E-Mail bei Rückgabeverzug an Kundinnen"
                      checked={field.value}
                      onChange={(_, data) =>
                        formik.setFieldValue(
                          'enableLendingOverdueReminder',
                          data.checked
                        )
                      }
                    />
                  );
                }}
              />
            </Section>
          </Grid.Column>
        </Grid>
      )}
    </Editor>
  );
};

export default memo(StoreEditor);
