import React, { memo } from 'react';
import { Label } from 'semantic-ui-react';
import { FormattedDate } from './FormattedDate';

export interface PaymentStatusProps {
  status?: API.TransactionStatus;
  completedAt?: string | null;
}

export const PayPalTransactionStatus = memo<PaymentStatusProps>(
  ({ status, completedAt }) => {
    if (status === 'created') {
      return <Label color="red">Erstellt</Label>;
    }
    if (status === 'pending') {
      return <Label color="orange">Wartend</Label>;
    }
    if (status === 'completed') {
      return (
        <Label color="green">
          Bezahlt am <FormattedDate value={completedAt} />
        </Label>
      );
    }

    return null;
  }
);
