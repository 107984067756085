import axiosbase, { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { clearSession } from './auth';

export const axios = axiosbase.create({
  withCredentials: true,
});

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  config.withCredentials = true;
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...config.headers,
  };

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      clearSession();
    }

    throw error;
  }
);

export interface Endpoint<T extends { id?: number }> {
  list(query?: PaginatedQueryParams): Promise<PaginatedItems<T>>;
  get(query?: { [key: string]: any }): Promise<T[]>;
  get(id: number, query?: { [key: string]: any }): Promise<T>;
  post(data: Omit<T, 'id'>): Promise<T>;
  put(id: number, data: Omit<T, 'id'>): Promise<T>;
  custom<T = any>(options: AxiosRequestConfig): Promise<T>;
  patch<B>(
    id: number,
    path: string,
    body?: B,
    config?: AxiosRequestConfig
  ): Promise<T>;
  delete(id: number): Promise<T>;
}

const endpoints = new Map<string, Endpoint<any>>();

export function createEndpoint<T>(path: string): Endpoint<T> {
  const url = `${process.env.BACKEND_URL}/${path}`;

  if (endpoints.has(path) === false) {
    endpoints.set(
      path,
      Object.freeze({
        async get(
          arg?: number | { [key: string]: any },
          params?: { [key: string]: any }
        ) {
          if (typeof arg === 'number') {
            return axios
              .get(`${url}/${arg}`, { params })
              .then((response) => response.data);
          }

          return axios
            .get(url, { params: arg })
            .then((response) => response.data);
        },

        async list(params?: PaginatedQueryParams) {
          return axios
            .get(`${url}/list`, { params })
            .then((response) => response.data);
        },

        async post(data: T) {
          return axios.post(url, data).then((response) => response.data);
        },

        async put(id: number, data: T) {
          return axios
            .put(`${url}/${id}`, data)
            .then((response) => response.data);
        },

        async patch<B>(
          id: number,
          path: string = '',
          body?: B,
          config: AxiosRequestConfig = {}
        ) {
          return axios
            .patch(`${url}/${id}/${path}`, body, config)
            .then((response) => response.data);
        },

        async delete(id: number) {
          return axios.delete(`${url}/${id}`).then((response) => response.data);
        },

        async custom(config: AxiosRequestConfig) {
          return axios
            .request({ ...config, baseURL: url })
            .then((response) => response.data);
        },
      })
    );
  }

  return endpoints.get(path) as Endpoint<T>;
}

export function useEndpoint<T>(endpoint: Endpoint<T>): [Endpoint<T>, boolean] {
  const [loading, setLoading] = useState(false);
  const decorated = useMemo(
    () =>
      Object.freeze({
        async get(
          arg?: number | { [key: string]: any },
          query?: { [key: string]: any }
        ) {
          setLoading(true);

          try {
            let response: any;

            if (typeof arg === 'number') {
              response = await endpoint.get(arg, query);
            } else {
              response = await endpoint.get(arg);
            }

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async list(params?: PaginatedQueryParams) {
          setLoading(true);

          try {
            let response: any;

            response = await endpoint.list(params);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async post(data: T) {
          setLoading(true);

          try {
            const response = await endpoint.post(data);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async put(id: number, data: T) {
          setLoading(true);

          try {
            const response = await endpoint.put(id, data);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async patch<B>(
          id: number,
          path: string = '',
          body?: B,
          config: AxiosRequestConfig = {}
        ) {
          setLoading(true);

          try {
            const response = await endpoint.patch(id, path, body, config);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async delete(id: number) {
          setLoading(true);

          try {
            const response = await endpoint.delete(id);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async custom(config: AxiosRequestConfig) {
          try {
            const response = await endpoint.custom(config);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },
      }),
    [endpoint]
  );

  return [decorated, loading];
}

export interface RemoteListHook<T> {
  data: T[];
  loading: boolean;
  load: (query?: { [key: string]: any }) => void;
  delete: (entity: T) => void;
  patch: <B>(
    entity: T,
    path: string,
    body?: B,
    config?: AxiosRequestConfig
  ) => Promise<void>;
}

export function useRemoteList<T extends API.BaseEntity>(
  endpoint: Endpoint<T>,
  query?: { [key: string]: any },
  autoload = true
): RemoteListHook<T> {
  const [data, update] = useState<T[]>([]);
  const [api, loading] = useEndpoint<T>(endpoint);

  const load = useCallback(
    async (queryOverride?: { [key: string]: any }) => {
      update(await api.get(queryOverride || query));
    },
    [query, api]
  );

  const deleteFn = useCallback(
    async (entity: T) => {
      await endpoint.delete(entity.id);
      await load();
    },
    [load, api]
  );

  const patch = useCallback(
    async <B>(
      entity: T,
      path: string,
      body?: B,
      config: AxiosRequestConfig = {}
    ) => {
      await endpoint.patch(entity.id, path, body, config);
      return await load();
    },
    [load, api]
  );

  const hook = useMemo<RemoteListHook<T>>(
    () => ({
      data,
      load,
      delete: deleteFn,
      patch,
      loading,
    }),
    [query, data, load, deleteFn, loading, api]
  );

  useEffect(() => {
    if (autoload) {
      load();
    }
  }, [query]);

  return hook;
}

interface PaginatedItems<T> {
  items: T[];
  totalCount: number;
}

interface PaginatedQueryParams {
  take?: number;
  skip?: number;
  [key: string]: any;
}

export interface RemotePaginatedListHook<T> extends RemoteListHook<T> {
  paginatedData: PaginatedItems<T>;
}

export function useRemotePaginatedList<T extends API.BaseEntity>(
  endpoint: Endpoint<T>,
  query?: PaginatedQueryParams,
  autoload = true
): RemotePaginatedListHook<T> {
  const [data, update] = useState<PaginatedItems<T>>({
    items: [],
    totalCount: 0,
  });
  const [api, loading] = useEndpoint<T>(endpoint);

  const load = useCallback(
    async (queryOverride?: { [key: string]: any }) => {
      update(
        await api.list({
          ...(queryOverride || query),
        })
      );
    },
    [query, api]
  );

  const deleteFn = useCallback(
    async (entity: API.BaseEntity) => {
      await endpoint.delete(entity.id);
      await load();
    },
    [load, api]
  );

  const patch = useCallback(
    async <B>(
      entity: T,
      path: string,
      body?: B,
      config: AxiosRequestConfig = {}
    ) => {
      await endpoint.patch(entity.id, path, body, config);
      await load();
    },
    [load, api]
  );

  const hook = useMemo<RemotePaginatedListHook<T>>(
    () => ({
      data: [],
      paginatedData: data,
      load,
      delete: deleteFn,
      patch,
      loading,
    }),
    [query, data, load, deleteFn, loading, api]
  );

  useEffect(() => {
    if (autoload) {
      load(query);
    }
  }, [query]);

  return hook;
}

export interface RemoteSingleHook<T extends { id: number }> {
  data?: T;
  loading: boolean;
  load: (id: number) => Promise<T>;
  put<Dto = T>(id: number, data: Dto): Promise<T>;
  delete(id: number): Promise<T>;
  post<Dto = T>(data: Dto): Promise<T>;
  patch<Dto = T>(id: number, path: string, data?: Dto): Promise<T>;
  custom: (options: AxiosRequestConfig) => Promise<T>;
  clear: () => void;
}

export function useRemoteSingle<T extends { id: number }>(
  endpoint: Endpoint<T>
): RemoteSingleHook<T> {
  const [data, update] = useState<T>();
  const [api, loading] = useEndpoint(endpoint);

  const load = useCallback(
    async (id: number) => {
      const data = await api.get(id);
      update(data);

      return data;
    },
    [api]
  );

  const clear = useCallback(() => {
    update(undefined);
  }, [api]);

  const put = useCallback(
    async (id: number, data: any) => {
      const entity = await api.put(id, data);
      update(entity);
      return entity;
    },
    [api]
  );

  const post = useCallback(
    async (data: any) => {
      const entity = await api.post(data);
      update(entity);
      return entity;
    },
    [api]
  );

  const delete_ = useCallback(
    async (id: number) => {
      const entity = await api.delete(id);
      update(entity);
      return entity;
    },
    [api]
  );

  const patch = useCallback(
    async (id: number, path: string, data: any) => {
      const entity = await api.patch(id, path, data);
      update(entity);
      return entity;
    },
    [api]
  );

  const custom = useCallback(
    async (options: AxiosRequestConfig) => {
      const entity = await api.custom(options);
      update(entity);
      return entity;
    },
    [api]
  );

  const hook = useMemo<RemoteSingleHook<T>>(
    () => ({
      data,
      load,
      loading,
      clear,
      put,
      post,
      custom,
      patch,
      delete: delete_,
    }),
    [data, load, clear, delete_, loading]
  );

  return hook;
}
