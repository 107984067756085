import React, { ComponentType } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

interface SubMenuProps {}

export const SubMenu: ComponentType<SubMenuProps> = (props) => {
  const history = useHistory();
  return (
    <>
      <Menu.Item
        content="Alle Artikel"
        icon="boxes"
        as={Link}
        to={'/article/list'}
        active={history.location.pathname.endsWith('list')}
      />
      <Menu.Item
        content="Reihenfolge"
        icon="arrows alternate vertical"
        as={Link}
        to={'/article/position'}
        active={history.location.pathname.endsWith('position')}
      />
    </>
  );
};
