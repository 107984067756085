import React from 'react';
import { Dropdown, Input, List, Menu } from 'semantic-ui-react';
import { useListFilter } from 'shared/hooks';
import { ArticleDisposalEntry } from './ArticleDisposalDataTable';

interface ArticleDisposalFilter {
  term?: string;
  startDate?: string;
  endDate?: string;
  articleOwner?: number;
  disposalStore?: number;
  pointOfSale?: API.PointOfSale;
}

export function useArticleDisposalFilter(
  initialQuery: any = {},
  stores: API.Store[]
) {
  const filter = useListFilter<ArticleDisposalFilter, ArticleDisposalEntry>(
    ({ onChange, values }) => (
      <>
        <Menu.Item>
          <Dropdown
            value={values.disposalStore}
            clearable
            placeholder="Verkaufsort"
            selection
            onChange={(e: any, { value }) => {
              onChange('disposalStore', value);
            }}
            options={stores.map((s) => ({ text: s.name, value: s.id }))}
          />
        </Menu.Item>
        <Menu.Item>
          <Dropdown
            value={values.pointOfSale}
            clearable
            placeholder="Point Of Sale"
            selection
            onChange={(e: any, { value }) => {
              onChange('pointOfSale', value);
            }}
            options={[
              { text: 'Online', value: 'online' },
              { text: 'Store', value: 'store' },
            ]}
          />
        </Menu.Item>
        <Menu.Item>
          <Dropdown
            value={values.articleOwner}
            clearable
            placeholder="Besitzer"
            selection
            onChange={(e: any, { value }) => {
              onChange('articleOwner', value);
            }}
            options={stores.map((s) => ({ text: s.name, value: s.id }))}
          />
        </Menu.Item>
        <Menu.Item>
          <Input
            placeholder="Kundin"
            onChange={(e) => onChange('term', e.target.value)}
            value={values.term ? values.term : ''}
          />
        </Menu.Item>
        <List.Item>
          <Input
            type="date"
            placeholder="Anfangsdatum"
            onChange={(e) => onChange('startDate', e.target.value)}
            value={values.startDate ? values.startDate : ''}
          />
        </List.Item>
        <List.Item>
          <Input
            type="date"
            placeholder="Enddatum"
            onChange={(e) => onChange('endDate', e.target.value)}
            value={values.endDate ? values.endDate : ''}
          />
        </List.Item>
      </>
    ),
    {
      endpointUrl: 'admin/disposal/article-disposal',
      initialState: initialQuery,
    }
  );

  return filter;
}
