import { createEndpoint, useRemoteSingle } from 'core';
import { Field, FieldProps } from 'formik';
import React, { ComponentType, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Button, Form, Grid, List, Menu, Table } from 'semantic-ui-react';
import { Currency, Editor, FormattedDate, Section } from 'shared/components';
import { object, string } from 'yup';
interface Props {}

export interface Values {
  email: string;
  gender: number;
  firstname: string;
  lastName: string;
  streetAndNumber: string;
  city: string;
  postalCode: string;
  country: string;
  dateOfBirth: string;
  phone: string;
}

export const schema = object().shape({
  email: string().email().required(),
  firstName: string().required(),
  lastName: string().required(),
  streetAndNumber: string().required(),
  city: string().required(),
  postalCode: string().required(),
  country: string().required(),
  dateOfBirth: string().required(),
  phone: string(),
});

function TextField({
  formik,
  name,
  label,
}: {
  formik: any;
  name: string;
  label: string;
}) {
  return (
    <Field
      name={name}
      render={({ field }: FieldProps) => (
        <Form.Input
          error={formik.errors[name]}
          label={label}
          {...field}
          value={field.value || ''}
        />
      )}
    />
  );
}

export const CustomerEditor: ComponentType<Props> = (props) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { addToast } = useToasts();

  const customer = useRemoteSingle(
    createEndpoint<API.Customer>('admin/customer')
  );

  const invitationEndpoint = createEndpoint('invitations');

  const backToList = useCallback(() => {
    history.push('/customer');
  }, []);

  const onSave = useCallback(() => {
    addToast('Die Kundinnendaten wurden gespeichert.', {
      appearance: 'success',
      autoDismiss: true,
    });
    backToList();
  }, []);

  const onSendInvitation = useCallback(() => {
    if (customer.data?.id) {
      invitationEndpoint.post({ user: customer.data.id });
      addToast('Die Kundin hat eine Einladungsmail erhalten.', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
  }, [customer.data]);

  useEffect(() => {
    customer.load(parseInt(id)).catch(backToList);
  }, [id]);

  return (
    <Editor
      title="Kundin"
      endpoint="admin/customer"
      validationSchema={schema}
      onCancel={backToList}
      onSave={onSave}
      id={parseInt(id)}
    >
      {(formik) => {
        return (
          <>
            <Menu>
              <Menu.Item>
                <Button onClick={onSendInvitation} type="button">
                  Einladungs-Link verschicken
                </Button>
              </Menu.Item>
            </Menu>
            <Grid columns={2}>
              <Grid.Column width="6">
                <Section header="Kundin">
                  <TextField formik={formik} name="firstName" label="Vorname" />
                  <TextField formik={formik} name="lastName" label="Nachname" />
                  <TextField formik={formik} name="email" label="E-Mail" />
                  {/* Geschlecht */}
                  {/* Geburtsdatum */}
                  <TextField
                    formik={formik}
                    name="streetAndNumber"
                    label="Straße und Hausnummer"
                  />
                  <TextField formik={formik} name="postalCode" label="PLZ" />
                  <TextField formik={formik} name="city" label="Stadt" />
                  <TextField formik={formik} name="country" label="Land" />
                  <TextField formik={formik} name="phone" label="Telefon" />
                </Section>
              </Grid.Column>
              <Grid.Column width="10">
                <Section header="Abonnements">
                  <Table compact>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Store</Table.HeaderCell>
                        <Table.HeaderCell>Beginn</Table.HeaderCell>
                        <Table.HeaderCell>Ende</Table.HeaderCell>
                        <Table.HeaderCell>Gebühr</Table.HeaderCell>
                        <Table.HeaderCell>Rabatte</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {customer.data?.subscriptions?.map((s) => (
                        <Table.Row key={s.id}>
                          <Table.Cell>{s.store.name}</Table.Cell>
                          <Table.Cell>
                            <FormattedDate
                              format="dd.MM.yyyy"
                              value={s.startAt}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <FormattedDate
                              format="dd.MM.yyyy"
                              value={s.endAt}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Currency value={s.feeWithDiscount} />
                          </Table.Cell>
                          <Table.Cell>
                            <List>
                              {s.discounts?.map((d) => (
                                <List.Item
                                  key={d.id}
                                  header={d.description}
                                  content={
                                    <>
                                      {d.type === 1 && (
                                        <Currency value={d.amount} />
                                      )}
                                      {d.type === 0 && <>{d.amount} %</>}
                                      <br />
                                      von{' '}
                                      <FormattedDate
                                        format="dd.MM.yyyy"
                                        value={d.startAt}
                                      />
                                      <br />
                                      {d.endAt && (
                                        <>
                                          bis{' '}
                                          <FormattedDate
                                            format="dd.MM.yyyy"
                                            value={d.endAt}
                                          />
                                        </>
                                      )}
                                    </>
                                  }
                                />
                              ))}
                            </List>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Section>
                <Section header="Autorisierungen">
                  <List>
                    {customer.data?.authorizations?.map((a) => (
                      <List.Item
                        key={a.id}
                        header={a.store.name}
                        content={
                          <FormattedDate
                            format="dd.MM.yyyy"
                            value={a.authorizedAt}
                          />
                        }
                      />
                    ))}
                  </List>
                </Section>
              </Grid.Column>
            </Grid>
          </>
        );
      }}
    </Editor>
  );
};
