import React, { memo } from 'react';
import { useTranslator } from 'core';

export interface UserGroupsProps {
  value: string[];
}

export const UserGroups = memo<UserGroupsProps>(({ value }) => {
  const _ = useTranslator();

  return <>{value.map(group => _(`groups.${group}`)).join(', ')}</>;
});
