import {
  createTranslator,
  withDefaultLocale,
  withFallbackTranslation,
  withPlaceholders,
  withPluralizer,
  withTranslations,
} from '@translata/core';
import { createTranslatorProvider } from '@translata/react';

function getLocaleFromPath(path: string) {
  return path.replace(/.+\/i18n\/([a-z]{2})\.json/, '$1');
}

function getTranslationsFromPath(path: string, json: any) {
  return withTranslations(getLocaleFromPath(path), json);
}

const context = require.context('../../', true, /\/i18n\/[a-z]{2}\.json/);

const translations = [
  ...context.keys().map((path) => getTranslationsFromPath(path, context(path))),
];

export const Translator = createTranslatorProvider('de', (locale) =>
  createTranslator(
    ...translations,
    withPlaceholders(),
    withPluralizer(),
    withFallbackTranslation((key) => `missing translation: ${key}`),
    withDefaultLocale(locale)
  )
);

export type TranslatorFunction = ReturnType<typeof Translator.useTranslator>;

export function useTranslator() {
  return Translator.useTranslator();
}
