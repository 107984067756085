import React, { memo } from 'react';

export interface PaymentTypeProps {
  type: API.PaymentType;
}

export const PaymentType = memo<PaymentTypeProps>(({ type }) => {
  if (type === 0) {
    return <>Karte</>;
  }
  if (type === 1) {
    return <>Bar</>;
  }
  if (type === 2) {
    return <>PayPal</>;
  }
  if (type === 3) {
    return <>Überweisung</>;
  }
  return <>Unbekannt</>;
});
