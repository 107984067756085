import { createEndpoint, useEndpoint } from 'core';
import React, { ComponentType, useEffect, useState } from 'react';
import { Image, Table } from 'semantic-ui-react';
import { Currency, DataTable, FormattedDate, Page } from 'shared/components';
import { useTrashFilter } from './Filter';
interface Props {}

export const Overview: ComponentType<Props> = (props) => {
  const [storeEndpoint] = useEndpoint<API.Store>(createEndpoint('stores'));
  const [stores, setStores] = useState<API.Store[]>([]);
  const [filter, { list, query }] = useTrashFilter({ stores });

  useEffect(() => {
    storeEndpoint.get().then((response) => setStores(response));
  }, []);

  return (
    <Page header="Trash" loading={list.loading} actions={<></>}>
      {filter}
      <DataTable<API.InventoryArticle>
        list={list}
        query={query}
        rowComponent={({ data, ...props }) => (
          <Table.Row {...props}>
            <Table.Cell>{data.id}</Table.Cell>
            <Table.Cell>{data.name}</Table.Cell>
            <Table.Cell>
              {data.trashImage && (
                <a
                  href={`${process.env.BACKEND_URL}/files/${data.trashImage}?size=big`}
                  target="_blank"
                >
                  <Image
                    size="tiny"
                    src={`${process.env.BACKEND_URL}/files/${data.trashImage}?size=small`}
                  />
                </a>
              )}
            </Table.Cell>
            <Table.Cell>
              <FormattedDate value={data.deletedAt} format="dd.MM.yyyy" />
            </Table.Cell>
            <Table.Cell>{data.ownerStore?.name}</Table.Cell>
            <Table.Cell>
              <Currency value={data.price} />
            </Table.Cell>
          </Table.Row>
        )}
      >
        {(sorting: any) => (
          <Table.Row>
            <Table.HeaderCell
              content="ID"
              sorted={sorting.isSorted('article.id')}
              onClick={() => sorting.onSortChange('article.id')}
            />
            <Table.HeaderCell
              content="Name"
              sorted={sorting.isSorted('article.name')}
              onClick={() => sorting.onSortChange('article.name')}
            />
            <Table.HeaderCell content="Bild defekter Artikel" />
            <Table.HeaderCell
              content="Weggeworfen am"
              sorted={sorting.isSorted('article.deletedAt')}
              onClick={() => sorting.onSortChange('article.deletedAt')}
            />
            <Table.HeaderCell
              content="Besitzer Store"
              sorted={sorting.isSorted('article.ownerStore')}
              onClick={() => sorting.onSortChange('article.ownerStore')}
            />
            <Table.HeaderCell
              content="Preis"
              sorted={sorting.isSorted('article.price')}
              onClick={() => sorting.onSortChange('article.price')}
            />
          </Table.Row>
        )}
      </DataTable>
    </Page>
  );
};
