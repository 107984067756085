import { ITEMS_PER_PAGE, RemotePaginatedListHook, usePagination } from 'core';
import React, { ComponentType, PropsWithChildren, useMemo } from 'react';
import { Table, TableProps, TableRowProps } from 'semantic-ui-react';
import {
  SelectionHook,
  SelectionProps,
  useSelection,
  useSorting,
} from '../hooks';
import { PaginationBar } from './PaginationBar';

export type DataTableRowProps<T> = SelectionProps & TableRowProps & { data: T };

interface Props<T = any> extends TableProps {
  rowComponent: ComponentType<DataTableRowProps<T>>;
  list: RemotePaginatedListHook<T>;
  query: any;
}

export function DataTable<T>({
  children,
  list,
  query,
  rowComponent: Component,
  ...props
}: PropsWithChildren<Props<T>>) {
  const selection = useSelection<T>(list ? list.paginatedData.items : []);

  const { isSorted, onSortChange, sortedQuery } = useSorting(
    list,
    query,
    () => {
      pagination.setSkip(0);
    }
  );

  const pagination = usePagination<T>(
    list.paginatedData.totalCount || 0,
    query.take || ITEMS_PER_PAGE,
    list,
    sortedQuery
  );

  const [withSelectionProps] = selection;
  const rows = useMemo(
    () =>
      withSelectionProps.map(([entity, props], index) => (
        <Component key={index} {...props} data={entity} />
      )),
    [withSelectionProps, Component]
  );

  return (
    <>
      {pagination && <PaginationBar list={list!} pagination={pagination} />}
      <Table
        {...props}
        selection={selection as SelectionHook<T>}
        sortable={!!pagination}
      >
        <Table.Header>
          {typeof children === 'function'
            ? children({ isSorted, onSortChange })
            : children}
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
      {pagination && <PaginationBar list={list!} pagination={pagination} />}
    </>
  );
}
