import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-charts';
import { Dropdown } from 'semantic-ui-react';
import { useFilter } from 'shared/hooks';

const axes = [
  { primary: true, type: 'ordinal', position: 'bottom' },
  { type: 'linear', position: 'left', stacked: true },
];

const start = 2016;
const today = parseInt(moment().format('YYYY'));
const years = Array.from(Array(today - (start - 1)).keys()).map((y) => {
  const value = (today - y).toString();
  return { text: value, value };
});

const monthNames = [
  'Jan',
  'Feb',
  'März',
  'Apr',
  'Mai',
  'Juni',
  'Juli',
  'Aug',
  'Sept',
  'Okt',
  'Nov',
  'Dez',
];

export const SubscriptionStats = () => {
  const [stats, setStats] = useState([]);
  const [filter, { data, loading }] = useFilter<{ year: string }>(
    ({ onChange, values }) => (
      <>
        <Dropdown
          selection
          placeholder="Jahr"
          value={values.year}
          options={years}
          onChange={(event, data) => {
            onChange('year', data.value);
          }}
        />
      </>
    ),
    {
      initialState: { year: '2020' },
      endpointUrl: 'admin/subscription/stats',
      renderSummary: false,
    }
  );

  useEffect(() => {
    setStats(
      data.map((s: any) => ({
        label: s.store,
        data: s.subscriptions.map((pair: [number, number]) => [
          monthNames[pair[0] - 1],
          pair[1],
        ]),
        type: 'bar',
      }))
    );
  }, [data]);

  return (
    <div style={{ height: '70vh' }}>
      {filter}
      {!loading && data.length && (
        <Chart
          series={{ type: 'bar' }}
          type="bar"
          data={stats}
          axes={axes}
          tooltip
        />
      )}
    </div>
  );
};
