import React, {
  ComponentType,
  HTMLAttributes,
  memo,
  PropsWithChildren,
} from 'react';
import { Menu, Sidebar } from 'semantic-ui-react';
import { AppMenu } from './AppMenu';

export interface AppSidebarProps {
  visible: boolean;
  hasSubmenu: boolean;
}

export const AppSidebar: ComponentType<
  HTMLAttributes<HTMLDivElement> & PropsWithChildren<AppSidebarProps>
> = memo(({ visible, children, hasSubmenu, ...props }) => {
  return (
    <Sidebar.Pushable style={{ paddingTop: 40 }} {...props}>
      <Sidebar
        id="sidebar-menu"
        as={Menu}
        animation="overlay"
        direction="left"
        inverted
        vertical
        visible={visible}
        width="thin"
        style={{ paddingTop: hasSubmenu ? 80 : 40 }}
      >
        <AppMenu />
      </Sidebar>
      <Sidebar.Pusher>{children}</Sidebar.Pusher>
    </Sidebar.Pushable>
  );
});
