import { createEndpoint, useEndpoint } from 'core';
import React, { ComponentType, useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { Currency, DataTable, FormattedDate, Page } from 'shared/components';
import { useDonationFilter } from './Filter';
interface Props {}

export const Overview: ComponentType<Props> = (props) => {
  const [storeEndpoint] = useEndpoint<API.Store>(createEndpoint('stores'));
  const [stores, setStores] = useState<API.Store[]>([]);
  const [filter, { list, query }] = useDonationFilter({ stores });

  useEffect(() => {
    storeEndpoint.get().then((response) => setStores(response));
  }, []);

  return (
    <Page header="Spenden" loading={list.loading} actions={<></>}>
      {filter}
      <DataTable<API.InventoryArticle>
        query={query}
        list={list}
        rowComponent={({ data, ...props }) => (
          <Table.Row {...props}>
            <Table.Cell>{data.id}</Table.Cell>
            <Table.Cell>{data.name}</Table.Cell>
            <Table.Cell>{data.donationReason}</Table.Cell>
            <Table.Cell>
              <FormattedDate value={data.deletedAt} format="dd.MM.yyyy" />
            </Table.Cell>
            <Table.Cell>
              <Currency value={data.price} />
            </Table.Cell>
          </Table.Row>
        )}
      >
        {({ isSorted, onSortChange }: any) => (
          <Table.Row>
            <Table.HeaderCell
              content="ID"
              sorted={isSorted('article.id')}
              onClick={() => onSortChange('article.id')}
            />
            <Table.HeaderCell
              content="Name"
              sorted={isSorted('article.name')}
              onClick={() => onSortChange('article.name')}
            />
            <Table.HeaderCell
              content="Grund der Spende"
              sorted={isSorted('article.donationReason')}
              onClick={() => onSortChange('article.donationReason')}
            />
            <Table.HeaderCell
              content="Gespendet am"
              sorted={isSorted('article.deletedAt')}
              onClick={() => onSortChange('article.deletedAt')}
            />
            <Table.HeaderCell
              content="Preis"
              sorted={isSorted('article.price')}
              onClick={() => onSortChange('article.price')}
            />
          </Table.Row>
        )}
      </DataTable>
    </Page>
  );
};
