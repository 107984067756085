import { format as formatDate, parseISO } from 'date-fns';
import React, { memo } from 'react';

export interface FormattedDateProps {
  value?: string | Date | null;
  format?: string;
}

export const FormattedDate = memo<FormattedDateProps>(
  ({ value, format = 'dd.MM.yyyy HH:mm' }) => {
    if (!value) {
      return null;
    }
    return (
      <>
        {formatDate(
          typeof value === 'string' ? parseISO(value) : value,
          format
        )}
      </>
    );
  }
);
