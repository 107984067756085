import React, { ComponentType } from 'react';
interface Props {
  error?: string;
}
export const Error: ComponentType<Props> = ({ error }) => {
  if (!error) {
    return null;
  }
  return <span className="error">{error}</span>;
};
